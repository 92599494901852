import './ChatWindow.css'
import { useEffect, useRef, useState } from 'react'
import CancelIcon from '../../../../../component/icon/cancel'
import { getUserChats } from '../../../http'
import ChatListItem from './ChatListItem/ChatListItem'
import { IcoAdd } from '../../../../../component'
import Modal, { MODAL_INIT_PROPS } from '../../../../../newcomponents/modal/modal'
import NewChatModal from './NewChatModal/NewChatModal'
import Conversation from './Conversation/Conversation'
import { useApp } from '../../../../../layout/App'
import BellIcon from '../../../../../component/icon/bell'
import CrossedBellICon from '../../../../../component/icon/crossed-bell'
import { IcoSearch } from '../../../../../component'
import { Input } from '../../../../../component'

export default function ChatWindow({ isOpen, setIsOpen, onlineUsers, lastReadMessages, setLastReadMessages, onMessagesUpdated, sendNotification }) {

    const [modalInfo, setModalInfo] = useState(MODAL_INIT_PROPS)
    const [chats, setChats] = useState([])
    const [chatSelected, setChatSelected] = useState(null)
    const [nextPage, setNextPage] = useState(null); // pagination next URL
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('')
    const [isNotificationOn, setIsNotificationOn] = useState(true);

    const searchTimeout = useRef(null) 

    const onChatsUpdate = (chat) => {
        if (isOpen) {
            getChatsByUser()
        }
        if (chatSelected == chat) {
            setChatSelected(null)
        }
    }

    const playNotificationSound = () => {
        const audio = new Audio('/webrtc/audio/messageNotification.mp3')
        audio.play().catch((error) => {
            console.error('Erro ao tocar o som:', error);
        });
    };

    // const disconnectUserStatusWebSocket = () => {
    //     intentionalDisconnect.current = true
    //     if (ws.current) {
    //         ws.current.close()
    //         ws.current = null
    //     }
    // }

    const getChatsByUser = async (url = null, append = false) => {
        try {
            setIsLoading(true);
    
            const res = await getUserChats(url, searchTerm);

            const newChats = res.data.results;
            const nextPageUrl = res.data.next;
            
            setChats((prevChats) => append ? [...prevChats, ...newChats] : newChats); 
            setNextPage(nextPageUrl);
        } catch (error) {
            console.error("Erro ao carregar chats:", error);
        } finally {
            setIsLoading(false);
        }
    };
    

    const updateLastMessageRead = (chatId, updatedInfo) => {
        setLastReadMessages(prevMessages => {
            const chatIndex = lastReadMessages.findIndex(info => info.chat_id === chatId);

            const updatedMessages = [...prevMessages];
            updatedMessages[chatIndex] = {
                ...prevMessages[chatIndex],
                ...updatedInfo,
                unread_count: 0,
            };
            onMessagesUpdated()

            return updatedMessages
        })
    }
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };
    const handleOpenNewChatModal = () => {
        setModalInfo({
            open: true,
            title: 'Nova Conversa',
            content: <NewChatModal setModalInfo={setModalInfo} onChatsUpdate={onChatsUpdate} />,
        })
    }

    const handleSelectChat = (chat = null) => {
        if (!chat) setChatSelected(null)

        if (!chatSelected) {
            setChatSelected(chat)
        } else {
            if (chatSelected.id === chat?.id) {
                setChatSelected(null)
            } else {
                setChatSelected(chat)
            }
        }
    }

    const handleCloseModal = () => {
        setIsOpen(false)
        // disconnectUserStatusWebSocket()
    }

    const handleChangeNotificationStatus = () => {
        setIsNotificationOn(!isNotificationOn)
    }

    useEffect(() => {
        const handleChatNotification = async (event) => {
            
            if (isNotificationOn) {
                playNotificationSound();
            }
            onMessagesUpdated()
            getChatsByUser()
        };
        window.addEventListener('chatNotification', handleChatNotification);
    
        return () => {
            window.removeEventListener('chatNotification', handleChatNotification);
        };
    }, [isNotificationOn]);

    useEffect(async () => {
        if (isOpen) {
            getChatsByUser()
        }
    }, [isOpen])

    useEffect(() => {
        const container = document.querySelector('.ChatList');
        if (!container) return;
    
        let timeout;
    
        const handleScroll = () => {
            clearTimeout(timeout);
    
            if (!isLoading) {
                timeout = setTimeout(() => {
                    if (
                        container.scrollTop + container.clientHeight >= container.scrollHeight - 50 &&
                        nextPage
                    ) {
                        getChatsByUser(nextPage, true);
                    }
                }, 200); 
            }
        };
    
        container.addEventListener('scroll', handleScroll);
    
        return () => {
            container.removeEventListener('scroll', handleScroll);
            clearTimeout(timeout);
        };
    }, [nextPage, isLoading]);
    
    useEffect(() => {
        if (searchTimeout.current) {
            clearTimeout(searchTimeout.current);
        }
    
        searchTimeout.current = setTimeout(() => {
            getChatsByUser();
        }, 300);
    
        return () => clearTimeout(searchTimeout.current); 
    }, [searchTerm]); 

    return (
        <div className={`ChatWindow ${isOpen ? 'open' : 'close'}`}>
            <Modal {...modalInfo} dismissFn={() => setModalInfo(MODAL_INIT_PROPS)} />
            <div className='ChatWindow-Header'>
                <div className='ChatWindow-CloseBtn-Box'>
                    <button className='ChatWindow-CloseBtn' onClick={handleCloseModal}>
                        <CancelIcon width={20} height={20} color={'#000'} />
                    </button>
                </div>
                <div>
                    <div className='ChatWindow-Title-Div'>
                        <h1 className='ChatWindow-Title'>Suas Conversas</h1>
                        <div title='Nova Conversa' className='ChatWindow-AddBtn' onClick={handleOpenNewChatModal}>
                            <IcoAdd />
                        </div>

                    </div>
                    <div className='ChatWindow-Actions-Div'>
                        <div className='ChatWindow-Actions-Search'>
                            <div className='ChatWindow-Actions-Search-Input-Box'>
                                <Input
                                 style={{ width: '100%' }}
                                 max='60'
                                 placeholder='Buscar chat por nome' 
                                 value={searchTerm}
                                 action={handleSearch}
                                 ></Input>
                            </div>
                            <div className='ChatWindow-Actions-Search-Icon'>
                                <IcoSearch style={{ fill: '#bbb', width: '1rem', height: '1rem' }}></IcoSearch>
                            </div>
                        </div>
                        <div className='ChatWindow-Actions-Notification' title={isNotificationOn ? 'Notificações Ativadas' : 'Notificações Desativadas'} onClick={handleChangeNotificationStatus}>
                            {isNotificationOn ? <BellIcon style={{ strokeWidth: '15', stroke: '#000', width: '1.325rem', height: '1.325rem' }} ></BellIcon> : <CrossedBellICon style={{ strokeWidth: '15', stroke: '#000', width: '1.325rem', height: '1.325rem' }} ></CrossedBellICon>}
                        </div>
                    </div>
                </div>
            </div>
            <div className='ChatList'>
                {chats.length > 0
                    ? chats?.map(chat => {
                        const lastReadMessage = lastReadMessages?.find(item => item.chat_id === chat.id)

                        return <div key={chat.id} className='ChatList-Item-Wrapper' onClick={() => handleSelectChat(chat)}> <ChatListItem chat={chat} onlineUsers={onlineUsers} lastReadMessage={lastReadMessage} onChatsUpdate={onChatsUpdate} setModalInfo={setModalInfo}  /> </div>
                    })
                    : <p className='ChatList-NoMessages'>Sem conversas para exibir</p>
                }
            </div>
            {isLoading &&
             <div className='Chatlist-Loading-Div'>
                <div className='ChatList-Loader'></div>
            </div>}
            

            {chatSelected && (
                <Conversation
                    chat={chatSelected}
                    handleSelectChat={handleSelectChat}
                    messagesInfo={lastReadMessages.find(item => item.chat_id === chatSelected.id)}
                    updateLastMessageRead={updateLastMessageRead}
                    sendNotification={sendNotification}
                    onChatsUpdate={onChatsUpdate}
                    setModalInfo={setModalInfo}
                />
            )}
        </div>
    )
}