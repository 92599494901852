import './NewEditVitalSign.css'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { getBodyTemperatureResults, getHeartRateResults, getOxygenSaturationResults, getPressureResult, getRespirationRateResults, strpVitalSign } from '../../utils'
import { AddAlert, AlertType, Input, idade } from '../../../../../../../../../../component'
import AlertRowText, {  } from '../../../../../../../../../../component/alertRowText/alertRowText'
import { Mask1DecimalPlace } from '../../../../../../../../../../component/mask/mask'
import NewButton, {  } from '../../../../../../../../../../newcomponents/button/button'
import { putVitalSign } from '../../../../../../../../http'
import { loaded, loading } from '../../../../../../../../../../layout/redux/AppActions'
import { MODAL_INIT_PROPS } from '../../../../../../../../../../newcomponents/modal/modal'


export default function NewEditVitalSign({ person, attendanceID=null, initVitalSign=null, extraAction=() => null }) {
    const dispatch = useDispatch()
    const selectedLanguage = 'pt-BR'
    const [holdingVitalSign, setHoldingVitalSign] = useState(strpVitalSign(initVitalSign))
	const [holdingVitalSignErr, setHoldingVitalSignErr] = useState(strpVitalSign(initVitalSign))

    let age = idade(person?.birthday)

    const handleChange = event => {
		setHoldingVitalSignErr(strpVitalSign(initVitalSign))
		let cleanedValue =
			event.target.name === 'oxygen_saturation' ||
			event.target.name === 'body_temperature'
				? Mask1DecimalPlace(event.target.value)
				: event.target.value.replace(/\D/g, '')
		setHoldingVitalSign(prev => ({ ...prev, [`${event.target.name}`]: cleanedValue }))
	}

    const saveVitalSign = async () => {
        const payload = { ...holdingVitalSign, "attendance": attendanceID, "person": person }
        
		//const errors = {}
		//if (!payload?.oxygen_saturation) errors['oxygen_saturation'] = true
		//if (!payload?.body_temperature) errors['body_temperature'] = true
		//if (!payload?.heart_rate) errors['heart_rate'] = true
		//if (!payload?.respiration_rate) errors['respiration_rate'] = true
		//if (!payload?.systolic) errors['systolic'] = true
		//if (!payload?.diastolic) errors['diastolic'] = true
		//if (Object.values(errors)?.length) {
		//	setHoldingVitalSignErr(prev => ({ ...prev, ...errors }))
		//	return
		//}

		if (!payload?.oxygen_saturation && !payload?.body_temperature && !payload?.heart_rate 
			&& !payload?.respiration_rate && !payload?.systolic && !payload?.diastolic
		) {
			dispatch(AddAlert('Salvar Sinais Vitais', 'Preencha pelo menos um dos campos. ', AlertType.ERROR))
			return
		}

		dispatch(loading())
        try {
            await putVitalSign(payload)
            dispatch(AddAlert('Sinais Vitais', 'Sinais Vitais salvos com sucesso!', AlertType.SUCCESS))
            extraAction(MODAL_INIT_PROPS)
        } catch (err) {
            console.error('saveVitalSign', err)
        }
        dispatch(loaded())
    }

    return (
		<div>
			<div className='NewEditVitalSign-MainRow'>
				<div>
					<b>Saturação de oxigênio</b>
					<div className='NewEditVitalSign-MainRow-Input'>
						<Input
							name='oxygen_saturation'
							placeholder='%'
							maxLength={4}
							value={holdingVitalSign.oxygen_saturation}
							action={handleChange}
							autoComplete='off'
							errors={{ error: holdingVitalSignErr, message: holdingVitalSignErr?.oxygen_saturation }}
						/>
					</div>
					{holdingVitalSign.oxygen_saturation ? (
						<AlertRowText
							{...getOxygenSaturationResults(
								holdingVitalSign.oxygen_saturation,
								selectedLanguage
							)}
							outlined
						/>
					) : null}
				</div>
				<div>
					<b>Temperatura corporal</b>
					<div className='NewEditVitalSign-MainRow-Input'>
						<Input
							name='body_temperature'
							placeholder='°C'
							maxLength={4}
							value={holdingVitalSign.body_temperature}
							action={handleChange}
							autoComplete='off'
							errors={{ error: holdingVitalSignErr, message: holdingVitalSignErr?.body_temperature }}
						/>
					</div>
					{holdingVitalSign.body_temperature ? (
						<AlertRowText
							{...getBodyTemperatureResults(
								holdingVitalSign.body_temperature,
								age,
								selectedLanguage
							)}
							outlined
						/>
					) : null}
				</div>
				<div>
					<b>Frequência cardíaca</b>
					<div className='NewEditVitalSign-MainRow-Input'>
						<Input
							name='heart_rate'
							placeholder='bpm'
							maxLength={3}
							value={holdingVitalSign.heart_rate}
							action={handleChange}
							autoComplete='off'
							errors={{ error: holdingVitalSignErr, message: holdingVitalSignErr?.heart_rate }}
						/>
					</div>
					{holdingVitalSign.heart_rate ? (
						<AlertRowText
							{...getHeartRateResults(holdingVitalSign.heart_rate, age)}
							outlined
						/>
					) : null}
				</div>
				<div>
					<b>Frequência respiratória</b>
					<div className='NewEditVitalSign-MainRow-Input'>
						<Input
							name='respiration_rate'
							placeholder='mpm'
							maxLength={2}
							value={holdingVitalSign.respiration_rate}
							action={handleChange}
							autoComplete='off'
							errors={{ error: holdingVitalSignErr, message: holdingVitalSignErr?.respiration_rate }}
						/>
					</div>
					{holdingVitalSign.respiration_rate ? (
						<AlertRowText
							{...getRespirationRateResults(holdingVitalSign.respiration_rate, age, selectedLanguage)}
							outlined
						/>
					) : null}
				</div>
				<div>
					<b className='NewEditVitalSign-Subtitle'>Pressão Arterial (mmHg)</b>
					<div className='NewEditVitalSign-Pressure'>
						<Input
							name='systolic'
							placeholder='Sistólica'
							maxLength={3}
							value={holdingVitalSign.systolic}
							action={handleChange}
							autoComplete='off'
							errors={{ error: holdingVitalSignErr, message: holdingVitalSignErr?.systolic }}
						/>
						<Input
							name='diastolic'
							placeholder='Diastólica'
							maxLength={3}
							value={holdingVitalSign.diastolic}
							action={handleChange}
							autoComplete='off'
							errors={{ error: holdingVitalSignErr, message: holdingVitalSignErr?.diastolic }}
						/>
					</div>
					{holdingVitalSign.systolic && holdingVitalSign.diastolic ? (
						<AlertRowText
							{...getPressureResult(holdingVitalSign.systolic, holdingVitalSign.diastolic, selectedLanguage)}
							outlined
						/>
					) : null}
				</div>
			</div>
            <div className='NewEditVitalSign-BtnBox'>
                <NewButton 
                    onClick={saveVitalSign}
                    label='Salvar Sinais Vitais'
                />
            </div>
		</div>
	)
}