import './consultation-header.css'
import React, { useEffect, useState } from "react";
import { AddAlert, AlertType, MaskDataHora, idade } from '../../../../component'
import { useAttendance } from '../../consultation';
import SendIcon from '../../../../component/icon/send';
import CallIcon from '../../../../component/icon/telephone';
import { getAppointmentCIDTenField, postVideoCallToken } from '../../http';
import { useDispatch } from 'react-redux';
import Button, { ButtonModel } from '../../../../newcomponents/button/button';
import { MODAL_INIT_PROPS } from '../../../../newcomponents/modal/modal';
import PrinterIcon from '../../../../component/icon/newPrinter';
import { loaded, loading } from '../../../../layout/redux/AppActions';
import api from '../../../../helpers/api';
import TemplatesIcon from '../../../../component/icon/templates';

const ShareVideoCallTokenModal = ({ url }) => {
    const dispatch = useDispatch()
    const { setModalInfo } = useAttendance()

    const copyCallLink = () => {
        navigator.clipboard.writeText(url)
        dispatch(AddAlert('Link de chamada', `Link de chamada copiado com sucesso, agora compartilhe com o paciente.`, AlertType.SUCCESS))
        setModalInfo(MODAL_INIT_PROPS)
    }

    return <div className='ShareVideoCallTokenModal'>
        <p>Clique em <b>'Compartilhar link'</b> para copiar o endereço da chamada. Em seguida, você pode facilmente compartilhar este link com seu paciente.</p>
        <p>*Para garantir a melhor qualidade durante a consulta, recomendamos o uso de <b>laptops ou computadores</b>.</p>
        <div className='ShareVideoCallTokenModal-BtnBox'>
            <Button
                label='Compartilhar link de chamada'
                onClick={copyCallLink}
            />
            <Button
                label='Fechar'
                model={ButtonModel.SECONDARY}
                onClick={() => setModalInfo(MODAL_INIT_PROPS)}
            />
        </div>
    </div>
}

function formatDiseases(data) {
    const result = [];
    data.forEach(item => {
        item.selected.forEach(disease => {
            result.push(disease);
        });
    });
    return result;
}

export default function ConsultationHeader() {
    const dispatch = useDispatch()
    const {
        attendance,
        setModalInfo,
        setVideoCallToken,
        openTemplateTypesManager 
    } = useAttendance()
    const { person } = attendance
    const [lastReasonForConsultations, setLastReasonForConsultations] = useState([])
    const defaultUser = require('../../../../media/DefaultUser.png')

    useEffect(() => {
        fetchLastCIDTenFields({ "appointment__person__id": person?.id })
    }, [])

    const fetchLastCIDTenFields = async (params={}) => {
        try {
            let res = await getAppointmentCIDTenField(params)
            setLastReasonForConsultations(formatDiseases(res.data.results))
        } catch (err) {
            console.error('ConsultationHeader ~ fetchLastCIDTenFields ~ ', err)
        }
    }

    const getAge = () => {
        if (person?.birthday) {
            const age = idade(person.birthday)
            return (age.anos ? `${age.anos} ${age.anos <= 1 ? 'ano' : 'anos'}` : age.mes ? `${age.mes} ${age.mes <= 1 ? 'mes' : 'meses'}` : '0')
        } else {
            return '-'
        }
    }

    const handleCall = async (params={}) => {
        await postVideoCallToken({ ...params, attendance: attendance.id })
            .then((res) => {
                if (params['mode'] === 'visitant') {
                    setModalInfo(prev => ({
                        ...prev,
                        open: true,
                        title: 'Compartilhar chamada com paciente',
                        content: <ShareVideoCallTokenModal url={res.data.url} />
                    }))
                } else {
                    setVideoCallToken(res.data.token)
                }
            })
            .catch(err => console.error(err))
    }

    const handlePrintAppointment = async () => {
        dispatch(loading())
        try {
          await api.get(`appointment/print/?attendance_id=${attendance?.id}`)
            .then(res => {
                const link = document.createElement("a");
                link.href = res.data.base64
                link.download = 'prontuário-de-atendimento.pdf'
                link.click()
            })
            .catch(err => {
              console.error('MedicalRecordHistory ~ handleSubmit ~ ', err)
            })
        } catch (err) {
          console.error('MedicalRecordHistory ~ handleSubmit ~ ', err)
        }
        dispatch(loaded())
    }

    const attendance__start = MaskDataHora(attendance?.start, 'DD/MMM/AAAA', '-')

    return <div className='ConsultationHeader'>
        <div className='ConsultationHeader-LeftColumn'>
            <div className='ConsultationHeader-LeftColumn-Profile'>
                <div className='ConsultationHeader-LeftColumn-Profile-Avatar'>
                    <div className='img'>
                    <img
                        className='ConsultationHeader-LeftColumn-Profile-Avatar-Img'
                        alt='Paciente foto de perfil'
                        src={person?.avatar_img_url || defaultUser}
                    />
                    </div>
                </div>
                <div className='ConsultationHeader-LeftColumn-InfoBox'>
                    <p className='ConsultationHeader-LeftColumn-Profile-TitleText'>{person?.social_name || person?.name || 'Nome não registrado' }</p>
                    <span className='ConsultationHeader-LeftColumn-Profile-AltText'><span className='ConsultationHeader-LeftColumn-Profile-AltText-Outlined'>{person?.sex === 'F' ? 'Feminino' : 'Masculino' }</span>{getAge()}</span>
                </div>
            </div>
            <div className='ConsultationHeader-LeftColumn-InfoBox'>
                    <p className='ConsultationHeader-LeftColumn-InfoBox-TitleText'>Protocolo:</p>
                    <span className='ConsultationHeader-LeftColumn-InfoBox-AltText'>{attendance?.protocol || '-'}</span>
                </div>
                <div className='ConsultationHeader-LeftColumn-InfoBox'>
                    <p className='ConsultationHeader-LeftColumn-InfoBox-TitleText'>Prontuário:</p>
                    <span className='ConsultationHeader-LeftColumn-InfoBox-AltText'>{person?.medical_record_number || '-'}</span>
                </div>
                <div className='ConsultationHeader-LeftColumn-InfoBox'>
                    <p className='ConsultationHeader-LeftColumn-InfoBox-TitleText'>Consultório:</p>
                    <span className='ConsultationHeader-LeftColumn-InfoBox-AltText'>{attendance?.service_location?.name || '-'}</span>
                </div>
                <div className='ConsultationHeader-LeftColumn-InfoBox'>
                    <p className='ConsultationHeader-LeftColumn-InfoBox-TitleText'>Instituição:</p>
                    <span className='ConsultationHeader-LeftColumn-InfoBox-AltText'>{attendance?.health_place?.name || '-'}</span>
                </div>
                <div className='ConsultationHeader-LeftColumn-InfoBox'>
                    <p className='ConsultationHeader-LeftColumn-InfoBox-TitleText'>Doenças e Problemas Relacionados (CID-10):</p>
                    <span className='ConsultationHeader-LeftColumn-InfoBox-AltText-Instances'>
                        {
                            lastReasonForConsultations?.length
                            ? lastReasonForConsultations.map(({code}, idx) =>  
                                idx < 5 
                                ? <span key={idx}>{code}</span> 
                                : idx === 5
                                ? <span
                                    style={{
                                        backgroundColor: '#eee',
                                        color: '#888888',
                                        border: '1px solid #888888'
                                    }}
                                    key={idx} 
                                    className='info'
                                >+{lastReasonForConsultations?.length - 5}</span>
                                : null)
                            : <span>Não cadastradas</span>
                        }

                    </span>
                    {
                        lastReasonForConsultations?.length
                        ? <div className='ConsultationHeader-CidTenField-Menu'>
                            <span className='ConsultationHeader-CidTenField-Menu-Instances'>
                            {
                                lastReasonForConsultations.map(({code, title}, idx) => 
                                    <span idx={idx}>{code} | {title}</span>
                                )
                            }
                            </span>
                        </div>
                        : null
                    }
                    
                </div>
                
        </div>
        <div className='ConsultationHeader-RightColumn'>
            
            <div className='ConsultationHeader-RightColumn-ActionRow'>
                <div
                    onClick={handleCall}
                    title="Selecionar Template"
                >
                    <CallIcon />
                </div>
                <div 
                    onClick={() => handleCall({ mode: 'visitant' })}
                    title='Enviar Link da Chamada'
                >
                    <SendIcon className='ConsultationHeader-RightColumn-ActionRow-SendIcon' />
                </div>
                <div
                    onClick={() => openTemplateTypesManager()}
                    title="Selecionar Template"
                >
                    <TemplatesIcon />
                </div>
                <div
                    onClick={handlePrintAppointment}
                    title="Imprimir atendimento"
                >
                    <PrinterIcon />
                </div>
            </div>

        </div>
    </div>
}
