import api from "../../helpers/api"


export async function getAppointmentsStats() {
    let response = await api.get(`appointment/stats/`)

    return response
}

export async function getUserChats(url = null, searchName = null) {
    let endpoint = url || `chat/chat-room/`;

    if (searchName) {
        endpoint += `?search=${encodeURIComponent(searchName)}`;
    }

    let response = await api.get(endpoint);
    return response;
}

export async function PostUserChat({ payload={} }) {
    let response = await api.post(`chat/chat-room/`, payload)

    return response
}

export async function getLastMessageRead(user_id) {
    let response = await api.get(`chat/last-read-messages/?user_id=${user_id}`)

    return response
}

export async function updateChatRoomName({ room_id, newName }) {
    let response = await api.put(`chat/chat-room/${room_id}/`, { name: newName });

    return response;
}


export async function updateChatRoomMembers({ room_id, addMembers = [], removeMembers = [] }) {
    let payload = {
        add_members: addMembers,
        remove_members: removeMembers
    };

    let response = await api.patch(`chat/chat-room/${room_id}/`, payload);

    return response;
}

export async function deleteChatRoom({ room_id }) {
    let response = await api.delete(`chat/chat-room/${room_id}/`);

    return response;
}
