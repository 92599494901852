import React from "react";

export default function RenderCurrentTimeLine() {
    const calculateCurrentTimePosition = () => {
      const now = new Date();
      const startOfDay = new Date(now);
      startOfDay.setHours(0,0,0,0)
      const diff = now.getTime() - startOfDay.getTime();
      const diffInHours = diff / (1000 * 60 * 60);
      return (diffInHours / 24) * 100;
    };
  
    const currentTimePosition = calculateCurrentTimePosition();
  
    return <div
      style={{
        position: 'absolute', 
        top: `${currentTimePosition}%`, 
        left: 0, 
        width: '100%', 
        height: '2px', 
        backgroundColor: 'red',
        // zIndex: 1 
      }}
    >
    </div>
  }