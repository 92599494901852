import './SortingRoom.css'
import React, { useContext, useEffect, useState, useRef } from 'react'
import { Paginate } from '../../../../component/index.js'
import Loading from '../../../../newcomponents/loading/loading.js'
import Modal, { MODAL_INIT_PROPS } from '../../../../newcomponents/modal/modal.js'
import { formatDateWithTimezoneFromDateObj } from '../../../../utils/convertDate.js'
import { getAttendancesHistory } from '../../http.js'
import { useApp } from '../../../../layout/App.js'
import SortingRoomUnit from './subcomponents/SortingRoomUnit/SortingRoomUnit.js'
import { parseAppointmentsURLQuerysetParams } from '../../helpers.js'
import SortingRoomAppointmentsFilters from './subcomponents/SortingRoomAppointmentsFilters/SortingRoomAppointmentsFilters.js'

const SortingRoomContext = React.createContext()

export function useSortingRoom() {
	return useContext(SortingRoomContext)
}

export const INIT_SORTING_ROOM_APPOINTMENT_FILTERS = {
	"name_or_cpf": "",
	"doctor__id__in": [],
	"specialty__id__in": [],
	"health_insurance__id__in": [],
	"classification__in": [],
	"decision__in": [],
	"priority__in": [],
	"result__in": [],
	"person__birthday__gte": "",
	"person__birthday__lte": "",
	"person__address__city__in": [],
	"service_location__id__in": [],
	"related_diseases_code": [],
	"ordering": "-start",
}

export default function SortingRoom() {
	const [loading, setLoading] = useState(true)
	const [attendances, setAttendances] = useState([])
	const [filters, setFilters] = useState(INIT_SORTING_ROOM_APPOINTMENT_FILTERS)
	const [pagination, setPagination] = useState({ pageNumber: 0, totalPages: 1, totalElements: 0 })
	const [modalProps, setModalProps] = useState(MODAL_INIT_PROPS)
	const searchTimeOut = useRef(null)
	const { currentHealthPlaceUser } = useApp()

    const fetchAttendancesHistory = async params => {
		const builtParams = parseAppointmentsURLQuerysetParams(filters)

		params = { 
			offset: 0,
			...builtParams, 
			...params, 
			limit: 18,
			decision__in: typeof params?.decision__in === 'string' && params?.decision__in?.length 
				? params?.decision__in 
				: 'WAITING_FOR_SCREENING,SCREENING_STARTED,SCREENING_COMPLETED',
			has_priority: true,
			health_place__id: currentHealthPlaceUser?.health_place?.id,
			start__gte: formatDateWithTimezoneFromDateObj(new Date(), '00', '00'),
			start__lte: formatDateWithTimezoneFromDateObj(new Date(), '23', '59'),
		}


		setLoading(true)
		try {
			let response = await getAttendancesHistory(params)
			setAttendances(response.data.results)
			setPagination({
				totalElements: response.data.count,
				totalPages: Math.ceil(response.data.count / 18),
				pageNumber: params.offset / 18,
			})
			setLoading(false)
		} catch (err) {
			setLoading(false)
			console.error('fetchAttendancesHistory ~ ', err)
		}
	}

	useEffect(() => {
		try {
			searchTimeOut.current && clearTimeout(searchTimeOut.current)
			searchTimeOut.current = setTimeout(async () => {
				await fetchAttendancesHistory({ offset: 0 })
			}, 300)
		} catch (err) {
			console.error('handleSearch', err)
		}
	}, [])

	const handleNextPage = e => {
		fetchAttendancesHistory({ offset: e * 18 })
	}

	return (
		<SortingRoomContext.Provider
			value={{
				attendances,
				pagination,
			}}>
			<Modal {...modalProps} dismissFn={() => setModalProps(MODAL_INIT_PROPS)} />
			<div className='SortingRoom-Content'>
				<SortingRoomAppointmentsFilters
					filters={filters}
					setFilters={setFilters}
					fetchAttendancesHistory={fetchAttendancesHistory}
					setModalProps={setModalProps}
				/>
				<div className='SortingRoom-Separator' />
				<div className='SortingRoom-Body'>
					{
						loading 
						? <div className='SortingRoom-Loading'><Loading /></div>
						: <div>
							{
								attendances?.length
								? <div className='SortingRoom-AttendanceUnitWrapper'>
									{attendances.map(attendance => <SortingRoomUnit
										attendance={attendance}
										setModalProps={setModalProps}
										fetchAttendancesHistory={fetchAttendancesHistory}
									/>)}
									<Paginate data={pagination} action={handleNextPage} />
								</div>
								: <div className='SortingRoom-NotFound'>
									<div>
										<p><b>Nenhum(a) paciente aguardando triagem</b></p>
									</div>
								</div>
							}
						</div> 
					}
				</div>
			</div>
		</SortingRoomContext.Provider>
	)
}
