import './TableLifeHabitsAndBehavior.css'
import React, { useEffect } from 'react'
import { IcoBin, IcoPencil, List, MaskDataHora, Paginate } from '../../../../../../../../../../component';

const defaultOptions = require('../../../../../../../../../../component/form/select/options.json')

export default function TableLifeHabitsAndBehavior({ lifeHabitsAndBehaviorHistory=[], fetchLifeHabitsAndBehaviorHistory= async () => null, openNewEditShowLifeHabitsAndBehaviorModal=() => null, openDeleteLifeHabitsAndBehaviorModal=() => null, pagination={} }) {
    useEffect(() => {
        fetchLifeHabitsAndBehaviorHistory({ offset: 0, limit: 10 })
    }, [])

    const handleNextPage = async e => {
        let offset = e * 10
		await fetchLifeHabitsAndBehaviorHistory({ "offset": offset, limit: 10 })
	}

    const headDefault = [
        { "colunm": "h_smokes", "text": "Fuma" },
        { "colunm": "h_exposure_to_secondhand_smoke", "text": "Fumo Pass." },
        { "colunm": "h_use_of_illicit_drugs", "text": "Drogas" },
        { "colunm": "h_physical_activity", "text": "Ativ. Fís." },
        { "colunm": "h_vaccinations_up_to_date", "text": "Vacinas" },
        { "colunm": "h_consumption_of_alcoholic_beverages_frequency", "text": "Álcool" },
        { "colunm": "h_updated_at", "text": "Últ. alt." },
        { "colunm": "h_appointment_date", "text": "Data de Atend." },
        { "colunm": "h_action", "text": <div className='TableLifeHabitsAndBehavior-HeaderRow-Action'>Ações</div>, width: '72px' } 
    ]

    return <div>
        <div className='TableLifeHabitsAndBehavior-Body-List'>
            <List
                head={headDefault}
                data={lifeHabitsAndBehaviorHistory}
                listCustom={cursor => {
                    const custom = cursor;

                    const renderTranslatedYesOrNoField = (selected) => {
                        return defaultOptions.ONLY_YES_NO_OR_NOT_EVALUATED.find(({id}) => id === selected)?.name  || '-'
                    }
                    const renderTranslatedPhysicalActivityFrequencyField = (selected) => {
                        return defaultOptions.PHYSICAL_ACTIVITY_FREQUENCY_CHOICES.find(({id}) => id === selected)?.name || '-'
                    }
                    const renderTranslatedConsumptionOfAlcoholicFrequencyField = (selected) => {
                        return defaultOptions.CONSUMPTION_OF_ALCOHOLIC_FREQUENCY_OR_NOT_EVALUATED.find(({id}) => id === selected)?.name || '-'
                    }

                    custom['h_smokes'] = renderTranslatedYesOrNoField(cursor?.smokes)
                    custom['h_exposure_to_secondhand_smoke'] = renderTranslatedYesOrNoField(cursor?.exposure_to_secondhand_smoke)
                    custom['h_use_of_illicit_drugs'] = renderTranslatedYesOrNoField(cursor?.use_of_illicit_drugs)
                    custom['h_physical_activity'] = renderTranslatedPhysicalActivityFrequencyField(cursor?.physical_activity)
                    cursor['h_vaccinations_up_to_date'] = renderTranslatedYesOrNoField(cursor?.vaccinations_up_to_date)
                    cursor['h_consumption_of_alcoholic_beverages_frequency'] = renderTranslatedConsumptionOfAlcoholicFrequencyField(cursor?.consumption_of_alcoholic_beverages_frequency)
                    custom['h_updated_at'] = MaskDataHora(cursor?.updated_at)?.data || '-'
                    custom['h_appointment_date'] = MaskDataHora(cursor?.attendance?.start)?.data || '-'
                    custom['h_action'] = (
                        <div className='TableLifeHabitsAndBehavior-ActionRow'>
                            <div
                                title='Editar Hábitos de Vida e Comportamento'
                            	onClick={() => openNewEditShowLifeHabitsAndBehaviorModal(cursor)}
                            >
                            	<IcoPencil />
                            </div>
                            <div
                                title='Excluir Hábitos de Vida e Comportamento'
                                onClick={() => openDeleteLifeHabitsAndBehaviorModal(cursor)}
                            >
                                <IcoBin />
                            </div>
                        </div>
                    )
                    return custom
                }}
            />
            <div className='TableLifeHabitsAndBehavior-Pagination'>
                <Paginate data={pagination} action={handleNextPage} />
            </div>
        </div>
        <div className='TableLifeHabitsAndBehavior-Legend'>
            <span>Legenda:</span>
            <ul>
                <li><strong>Fuma</strong> - Fuma</li>
                <li><strong>Fumo Pass.</strong> - Exposição ao fumo passivo</li>
                <li><strong>Drogas</strong> - Uso de drogas ilícitas</li>
                <li><strong>Ativ. Fís.</strong> - Atividade física</li>
                <li><strong>Vacinas</strong> - Vacinas em dia</li>
                <li><strong>Álcool.</strong> - Consumo de bebidas alcoólicas</li>
                <li><strong>Últ. alt.</strong> - Última Alteração</li>
                <li><strong>Data Atend.</strong> - Data de Atendimento</li>
            </ul>
        </div>
    </div>
}