import './DetailedAppointmentLogModal.css'
import React from 'react'
import { MaskDataHora } from '../../../../../../component'
import AppointmentInstance from './subcomponents/AppointmentInstance/AppointmentInstance'

export default function DetailedAppointmentLogModal({ attendance=null, setModalProps=()=> null, fetchAttendancesHistory=() => null}) {

    let tempHistoryDate = MaskDataHora(attendance?.history_date)

    return <div className='DetailedAppointmentLogModal'>
        <div className='DetailedAppointmentLogModal-Info'>
            <p>
                Dono da ação: {
                    attendance?.history_user?.name && attendance?.history_user?.email
                    ? <span>{attendance?.history_user?.name} | {attendance?.history_user?.email}</span>
                    : 'Sistema Salutho'
                } 
            </p>
            <p>
                Horário de alteração: <span>{tempHistoryDate?.data} {tempHistoryDate?.hora}</span>
            </p>
        </div>

        <AppointmentInstance attendance={attendance} setModalProps={setModalProps} fetchAttendancesHistory={fetchAttendancesHistory} />
    </div>
}