import './NewEditElimination.css'
import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import ReactQuill from 'react-quill';
import { AddAlert, AlertType, Input, adicionaZero } from '../../../../../../../../../../component';
import Modal, { MODAL_INIT_PROPS } from '../../../../../../../../../../newcomponents/modal/modal';
import { strpElimination } from '../../utils';
import NewButton, { ButtonModel } from '../../../../../../../../../../newcomponents/button/button';
import { putElimination } from '../../../../../../../../http';
import { loaded, loading } from '../../../../../../../../../../layout/redux/AppActions';
import NewSelect from '../../../../../../../../../../newcomponents/form/select/select';

const defaultOptions = require('../../../../../../../../../../component/form/select/options.json')

export default function NewEditElimination({ person, attendanceID=null, initElimination=null, extraAction=async () => null }) {
    const dispatch = useDispatch()
    const [holdingElimination, setHoldingElimination] = useState(strpElimination(initElimination))
    const [secondaryModal, setSecondaryModal] = useState(MODAL_INIT_PROPS)


    const saveElimination = async () => {
        const payload = { ...holdingElimination, "attendance": attendanceID, "person": person }

        if (
            payload?.urinary_elimination === 'NOT_EVALUATED'
            && payload?.intestinal_elimination === 'NOT_EVALUATED'
            && !payload?.elimination_observations
        ) {
			dispatch(AddAlert('Salvar Eliminação', 'Preencha pelo menos um dos campos. ', AlertType.ERROR))
			return
		}

        dispatch(loading())
        try {
            await putElimination(payload)
            dispatch(AddAlert('Eliminação', 'Eliminação salva com sucesso!', AlertType.SUCCESS))
            extraAction(MODAL_INIT_PROPS)
        } catch (err) {
            console.error('saveElimination', err)
        }
        dispatch(loaded())
    }

    const handleSelect = (event) => {
        setHoldingElimination(prev => ({ ...prev, [event.target.id]: event.target.selected }))
    }

    const handleEliminationObservation = (text) => {
        setHoldingElimination(prev => ({ ...prev, "elimination_observations": text }))
    }

    return <div className='NewEditElimination'>
        <Modal {...secondaryModal} dismissFn={() => setSecondaryModal(MODAL_INIT_PROPS)} />

        <div className='NewEditElimination-Grid'>
            <div className='NewEditElimination-Select'>
                <b>Eliminação urinária</b>
                <div >
                    <NewSelect
                        id='urinary_elimination'
                        options={defaultOptions.ONLY_NORMAL_NO_NORMAL_OR_NOT_EVALUATED}
                        onSelect={handleSelect}
                        selected={holdingElimination.urinary_elimination}
                        canBeEmpty={false}
                    />
                </div>
            </div>
            <div className='NewEditElimination-Select'>
                <b>Eliminação intestinal</b>
                <div >
                    <NewSelect
                        id='intestinal_elimination'
                        options={defaultOptions.ONLY_NORMAL_NO_NORMAL_OR_NOT_EVALUATED}
                        onSelect={handleSelect}
                        selected={holdingElimination.intestinal_elimination}
                        canBeEmpty={false}
                    />
                </div>
            </div>
        </div>
        <div>
            <p><b>Observações</b></p>
            <ReactQuill
                id='elimination_observations'
                theme="snow"
                value={holdingElimination.elimination_observations}
                onChange={(text) => handleEliminationObservation(text)}
                //onBlur={(previousRange, source, editor) => handleBlurAppointmentTextField(field_type, editor.getHTML())}
            />
        </div>
        <div className='NewEditElimination-BtnBox'>
            <NewButton
                label='Salvar eliminação'
                onClick={saveElimination}
            />
        </div>
    </div>
}