import './NextAppointment.css'
import { useEffect, useState } from 'react'
import AppointmentUnit from './subcomponents/appointmentUnit/appointmentUnit'
import api from '../../../../helpers/api'

export default function NextAppointment({ person=null }) {
    const [appointmentList, setAppointmentList] = useState([])

    useEffect(async () => {
        const res = await api.get(`appointment/history/get_patient_appointments/${person.id}/`)
        const appointments = res.data.reverse()

        const filteredAppointments = filterAppointmentByLatestStatus(appointments)

        setAppointmentList(filteredAppointments)
    }, [])

    const filterAppointmentByLatestStatus = (appointments) => {
        const appointmentMap = new Map()

        appointments.forEach(appointment => {
            const appointmentKey = `${appointment.start}-${appointment.doctor.id}`

            appointmentMap.set(appointmentKey, appointment);
        })
        return Array.from(appointmentMap.values());
    }

    return (
                appointmentList.length > 0 
                ? <section className='NextAppointment-Page'>
                    {appointmentList.map(appointment => <AppointmentUnit appointment={appointment} />)}
                </section>
                : <section className='NextAppointment-EmptyMessage-Box'>
                    <span className='NextAppointment-EmptyMessage'>Paciente sem atendimento agendado</span>
                </section>
    )
}