import './PhysicalAssessment.css';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ChartIcon from '../../../../../../../../component/icon/chart';
import ListIcon from '../../../../../../../../component/icon/list';
import NewTaskIcon from '../../../../../../../../component/icon/new_task';
import Modal, { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal';
import { AddAlert, AlertType } from '../../../../../../../../component';
import { getPhysicalAssessment } from '../../../../../../http';
import NewEditPhysicalAssessment from './subcomponents/NewEditPhysicalAssessment/NewEditPhysicalAssessment';
import TablePhysicalAssessment from './subcomponents/TablePhysicalAssessment/TablePhysicalAssessment';
import ShowPhysicalAssessment from './subcomponents/ShowPhysicalAssessment/ShowPhysicalAssessment';
import DeletePhysicalAssessment from './subcomponents/DeletePhysicalAssessment/DeletePhysicalAssessment';


export default function PhysicalAssessment({ person, attendanceID=null, }) {
  const dispatch = useDispatch()
  const [physicalAssessmentHistory, setPhysicalAssessmentHistory] = useState([])
  const [openSection, setOpenSection] = useState('TablePhysicalAssessment')
  const [modalInfo, setModalInfo] = useState(MODAL_INIT_PROPS)
  const [pagination, setPagination] = useState({ pageNumber: 0, totalPages: 1, totalElements: 0 })

  useEffect(() => {
    fetchPhysicalAssessmentHistory({ offset: 0 })
  }, [])

  const fetchPhysicalAssessmentHistory = async (params={}) => {
    params = { ...params, "active": true, "person__id": person?.id }
    
    try {
      let res = await getPhysicalAssessment(params)
      setPhysicalAssessmentHistory(res.data.results)
      setPagination({
        totalElements: res.data.count,
        totalPages: Math.ceil(res.data.count / 10),
        pageNumber: params.offset / 10,
      })
    } catch (err) {
      dispatch(AddAlert('Avaliação Física', 'Falha ao obter avaliação física', AlertType.ERROR))
      console.error('Avaliação Física ~ fetchPhysicalAssessmentHistory ~ ', err)
    }
  }

  const extraAction = async () => {
    await fetchPhysicalAssessmentHistory({ offset: 0, active: true })
    setModalInfo(MODAL_INIT_PROPS)
  }
  
  const openNewEditShowPhysicalAssessmentModal = (cursor) => {
    setModalInfo(prev => ({
      ...prev,
      open: true,
      title: 'Avaliação Física',
      content: <NewEditPhysicalAssessment
        initPhysicalAssessment={cursor}
        person={person}
        attendanceID={attendanceID}
        extraAction={extraAction} 
      />
    }))
  }
  
  const openDeletePhysicalAssessmentModal = (cursor) => {
    setModalInfo(prev => ({
      ...prev,
      open: true,
      title: 'Deleção de Avaliação Física',
      content: <DeletePhysicalAssessment
        physical_assessment={cursor} 
        extraAction={extraAction}
      />
    }))
  }

  const sections = {
    'ShowPhysicalAssessment': <ShowPhysicalAssessment 
      physicalAssessmentHistory={physicalAssessmentHistory}
      fetchPhysicalAssessmentHistory={fetchPhysicalAssessmentHistory}
      setModalInfo={setModalInfo} 
    />,
    'TablePhysicalAssessment': <TablePhysicalAssessment 
      physicalAssessmentHistory={physicalAssessmentHistory}
      fetchPhysicalAssessmentHistory={fetchPhysicalAssessmentHistory} 
      openNewEditShowPhysicalAssessmentModal={openNewEditShowPhysicalAssessmentModal}
      openDeletePhysicalAssessmentModal={openDeletePhysicalAssessmentModal}
      pagination={pagination}
    />
  }

  return (
    <div>
      <Modal {...modalInfo} dismissFn={() => setModalInfo(MODAL_INIT_PROPS)} />
      <div className='PhysicalAssessment-ActionRow'>
        <div
          onClick={() => openNewEditShowPhysicalAssessmentModal()}
          title='Nova avaliação física'
        >
          <NewTaskIcon />
        </div>
        {openSection === 'ShowPhysicalAssessment' ? (
          <div onClick={() => setOpenSection('TablePhysicalAssessment')} title='Ver Dados'>
            <ListIcon />
          </div>
        ) : (
          <div onClick={() => setOpenSection('ShowPhysicalAssessment')} title='Ver Gráficos'>
            <ChartIcon />
          </div>
        )}
      </div>
      <div>
        { sections[openSection] }
      </div>
    </div>
  );
}
