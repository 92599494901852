import './TemplateTypeSection.css'
import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import NewButton from "../../../../../../newcomponents/button/button";
import Modal, { MODAL_INIT_PROPS } from '../../../../../../newcomponents/modal/modal';
import NewEditHoldingTemplate from './subcomponents/NewEditHoldingTemplate/NewEditHoldingTemplate';
import { getTemplateTypes, putSaveTemplateTypeRememberChoice } from '../../../../http';
import { AddAlert, AlertType } from '../../../../../../component';
import { NewDocumentIcon } from '../../../../../../component/icon/newDocument';
import { NewCheckbox } from '../../../../../../newcomponents';
import { loading, loaded } from '../../../../../../layout/redux/AppActions';
import { useApp } from '../../../../../../layout/App';
import { useAttendance } from '../../../../consultation';
import NewPaginate from '../../../../../../newcomponents/NewPaginate/NewPaginate';


export default function TemplateTypeSection({ initHoldingTemplate=null, selectTemplateTypeConfirm=() => null }) {
    const { attendance } = useAttendance()
    const dispatch = useDispatch()
    const { loged } = useApp()
    const [modalInfoV2, setModalInfoV2] = useState(MODAL_INIT_PROPS)
    const [templateTypes, setTemplateTypes] = useState([])
    const [holdingTemplate, setHoldingTemplate] = useState(initHoldingTemplate)
    const [pagination, setPagination] = useState({
      pageNumber: 0,
      totalPages: 1,
      totalElements: 0
    })
    const [checkboxState, setCheckboxState] = useState([
      { id: 0, name: 'Lembrar minha escolha', checked: true }
    ]);

    useEffect(() => {
        fetchTemplateTypes({ offset: 0 })
        // eslint-disable-next-line
    }, [])

    const isClosed = attendance?.decision === 'CLOSE'
    const fetchTemplateTypes = async (params={}) => {
        params = { offset: 0, limit: 4, active: true, ...params }

        try {
            let response = await getTemplateTypes(params)
            setTemplateTypes(response.data.results)
            setPagination({
                totalElements: response.data.count,
                totalPages: Math.ceil(response.data.count / 4),
                pageNumber: params.offset / 4,
            })
        } catch (err) {
            console.error("TemplateTypeSection ~ fetchTemplateTypes ~ ", err)
            dispatch(AddAlert('Template de Prontuário', 'Falha ao obter templates', AlertType.ERROR))
        }
    }

    // Handle page changes
    const handlePageChange = (newPage) => {
        const offset = newPage * 4
        fetchTemplateTypes({ offset })
    }

    const extraActionHoldingTemplateModal = async () => {
        await fetchTemplateTypes({ offset: 0 })
        setModalInfoV2(MODAL_INIT_PROPS)
    }

    const openNewHoldingTemplateModal = () => {
        setModalInfoV2(prev => ({
            ...prev,
            open: true,
            title: 'Novo Template',
            content: <NewEditHoldingTemplate 
                extraAction={extraActionHoldingTemplateModal}
            />
        }))
    }

    const openEditHoldingTemplateModal = () => {
        setModalInfoV2(prev => ({
            ...prev,
            open: true,
            title: 'Editar Template',
            content: <NewEditHoldingTemplate
                initHoldingTemplate={holdingTemplate}
                extraAction={extraActionHoldingTemplateModal}
            />
        }))
    }

    const handleSelectTemplate = async () => {
        selectTemplateTypeConfirm(holdingTemplate?.id)
        if (checkboxState[0].checked) {
            await handleRememberChoiceToggle()
            await loged()
        }
    }

    const handleRememberChoiceToggle = async () => {
        dispatch(loading())
        try {
            let payload = {
                template_type_id: holdingTemplate?.id ? holdingTemplate?.id : null,
                remember_choice: checkboxState[0].checked
            }

            await putSaveTemplateTypeRememberChoice(payload)
            dispatch(AddAlert('Preferência de template', 'Preferência atualizada com sucesso!', AlertType.SUCCESS))
        } catch (err) {
            console.error("Failed to update remember choice:", err)
            dispatch(AddAlert('Preferência de template', 'Falha ao atualizar preferência', AlertType.ERROR))
        } finally {
            dispatch(loaded())
        }
    }

    return (
      <div className="TemplateTypeSection">
        <Modal 
          {...modalInfoV2}
          dismissFn={() => setModalInfoV2(MODAL_INIT_PROPS)}
        />

        {!isClosed && (
          <div className='TemplateTypeSection-ActionRow'>
            <div
              onClick={openNewHoldingTemplateModal}
              title="Novo Template"
            >
              <NewDocumentIcon />
            </div>
          </div>
        )}

        {
          isClosed
            ? <span>Não é possível alterar o template selecionado, pois esse <b>atendimento já foi finalizado</b>.</span>
            : templateTypes?.length
              ? <span>Selecione o template de prontuário que deseja utilizar para esse atendimento</span>
              : <span>Nenhum template foi localizado</span>
        }

        <div className='TemplateTypeSection-Manager'>
          {
            templateTypes.map((holdingTemplateType, index) => (
              <div 
                key={index} 
                className={`TemplateTypeSection-Manager-Instance ${holdingTemplateType?.id === holdingTemplate?.id ? 'active' : ''}`}
                onClick={() => {
                  if (isClosed) return
                  setHoldingTemplate(holdingTemplateType)
                }}
              >
                <div className='TemplateTypeSection-Manager-Instance-File'>
                  <div className='TemplateTypeSection-Manager-Instance-File-TopLeft'></div>
                  <span className="TemplateTypeSection-Manager-Instance-Title">
                    {holdingTemplateType?.name?.length > 45
                      ? holdingTemplateType.name.substring(0, 45) + "..."
                      : holdingTemplateType?.name
                    }
                  </span>
                  <span className='TemplateTypeSection-Manager-Instance-Subtitle'>
                    {holdingTemplateType?.specialty?.name || ''}
                  </span>
                </div>
              </div>
            ))
          }
        </div>

        {(holdingTemplate?.can_edit && holdingTemplate?.name != 'Template Padrão') && !isClosed && (
          <div className='TemplateTypeSection-AltActionBox'>
            <div
              className='TemplateTypeSection-AltActionBox-Btn'
              onClick={openEditHoldingTemplateModal}
            >
              <b>Editar Template</b>
            </div>
          </div>
        )}

        {!isClosed && (
          <NewCheckbox
            title=''
            state={checkboxState}
            setState={() => {
              const newCheckedStatus = !checkboxState[0].checked
              setCheckboxState([{
                id: 0,
                name: 'Lembrar minha escolha para os próximos atendimentos',
                checked: newCheckedStatus
              }])
            }}
            className=''
            defaultHandleCheckbox={false}
          />
        )}

        <div className='TemplateTypeSection-BottomRow'>
            <NewPaginate
                currentPage={pagination.pageNumber}
                totalPages={pagination.totalPages}
                onPageChange={handlePageChange}
            />

            <NewButton
                label='Confirmar seleção'
                onClick={handleSelectTemplate}
                disabled={!holdingTemplate || isClosed}
            />
        </div>
      </div>
    );
}
