import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useApp } from "../../../../../layout/App";
import AppointmentsSchedule from "./charts/AppointmentsSchedule";
import HeaderAppointmentsSummary from "./charts/HeaderAppointmentsSummary";
import './homeHealthProfessional.css'

export default function HomeHealthProfessional({ setIsLoading }) {

    const currentHealthPlaceUser = useApp()
    const [currentHealthPlace, setCurrentHealthPlace] = useState(null)

    const history = useHistory();


    useEffect(() => {

        if (currentHealthPlaceUser) {
            const personId = currentHealthPlaceUser.currentHealthPlaceUser?.user?.person?.id;
            setCurrentHealthPlace(currentHealthPlaceUser.currentHealthPlaceUser?.health_place)
            if (personId) {
                history.push(`/home?health_place_user__user__person=${personId}`);
            }
        }
    }, [currentHealthPlaceUser.currentHealthPlaceUser]);


    return (
        <div className="Home-HealthProfessional">

            <HeaderAppointmentsSummary currentHealthPlaceUser={currentHealthPlaceUser.currentHealthPlaceUser}></HeaderAppointmentsSummary>
            <AppointmentsSchedule setIsLoading={setIsLoading} currentHealthPlace={currentHealthPlace}></AppointmentsSchedule>
            {/* <div className="Home-HealhProfessional-Charts">
                <div style={{ width: '40%', height: '100%' }}>
                    <p> </p>
                </div>
            </div> */}
        </div>
    )
}