import './TableAnthropometry.css'
import React, { useEffect } from 'react'
import { IcoBin, IcoPencil, List, MaskDataHora, Paginate } from '../../../../../../../../../../component';
import { Mask1DecimalPlace } from '../../../../../../../../../../component/mask/mask';


export default function TableAnthropometry({ anthropometries=[], fetchAnthropometies= async () => null, openNewEditAnthropometryModal=() => null, openDeleteAnthropometryModal=() => null, pagination={} }) {
    useEffect(() => {
        fetchAnthropometies({ offset: 0, limit: 10 })
    }, [])

    const handleNextPage = async e => {
        let offset = e * 10
		await fetchAnthropometies({ "offset": offset, limit: 10 })
	}
    
    const headDefault = [
        { "colunm": "h_weight", "text": "Peso (kg)" },
        { "colunm": "h_height", "text": "Altura (cm)" },
        { "colunm": "h_abdominal_circumference", "text": "Circunferência abdominal (cm)" },
        { "colunm": "h_updated_at", "text": "Últ. alt.", "width": "100px" },
        { "colunm": "h_appointment_date", "text": "Data de Atend.", "width": "100px" },
        { "colunm": "h_action", "text": <div className='TableAnthropometry-HeaderRow-Action'>Ações</div>, width: '72px' } 
    ]

    return <div>
        <div className='TableAnthropometry-Body-List'>
            <List
                head={headDefault}
                data={anthropometries}
                listCustom={cursor => {
                    const custom = cursor;
                    custom['h_weight'] = cursor?.weight ? Mask1DecimalPlace(cursor?.weight) : '-'
                    custom['h_height'] = cursor?.height || '-'
                    custom['h_abdominal_circumference'] = cursor?.abdominal_circumference || '-'
                    custom['h_updated_at'] = MaskDataHora(cursor?.updated_at)?.data || '-'
                    custom['h_appointment_date'] = MaskDataHora(cursor?.attendance?.start)?.data || '-'
                    custom['h_action'] = (
                        <div className='TableAnthropometry-ActionRow'>
                            <div
                                title='Editar Antropometria'
                            	onClick={() => openNewEditAnthropometryModal(cursor)}
                            >
                            	<IcoPencil />
                            </div>
                            <div
                                title='Excluir Antropometria'
                                onClick={() => openDeleteAnthropometryModal(cursor)}
                            >
                                <IcoBin />
                            </div>
                        </div>
                    )
                    return custom
                }}
            />
            <div className='TableAnthropometry-Pagination'>
                <Paginate data={pagination} action={handleNextPage} />
            </div>
            
        </div>
        <div className='TableAnthropometry-Legend'>
            <span>Legenda:</span>
            <ul>
                <li><strong>Últ. alt.</strong> - Última Alteração</li>
                <li><strong>Data Atend.</strong> - Data de Atendimento</li>
            </ul>
        </div>
    </div>
}