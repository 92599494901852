import './LifeHabitsAndBehavior.css';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ChartIcon from '../../../../../../../../component/icon/chart';
import ListIcon from '../../../../../../../../component/icon/list';
import NewTaskIcon from '../../../../../../../../component/icon/new_task';
import Modal, { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal';
import { AddAlert, AlertType } from '../../../../../../../../component';
import { getLifeHabitsAndBehavior } from '../../../../../../http';
import NewEditLifeHabitsAndBehavior from './subcomponents/NewEditLifeHabitsAndBehavior/NewEditLifeHabitsAndBehavior';
import DeleteLifeHabitsAndBehavior from './subcomponents/DeleteLifeHabitsAndBehavior/DeleteLifeHabitsAndBehavior';
import TableLifeHabitsAndBehavior from './subcomponents/TableLifeHabitsAndBehavior/TableLifeHabitsAndBehavior';
import ShowLifeHabitsAndBehavior from './subcomponents/ShowLifeHabitsAndBehavior/ShowLifeHabitsAndBehavior';


export default function LifeHabitsAndBehavior({ person, attendanceID=null, }) {
  const dispatch = useDispatch()
  const [lifeHabitsAndBehaviorHistory, setLifeHabitsAndBehaviorHistory] = useState([])
  const [openSection, setOpenSection] = useState('TableLifeHabitsAndBehavior')
  const [modalInfo, setModalInfo] = useState(MODAL_INIT_PROPS)
  const [pagination, setPagination] = useState({ pageNumber: 0, totalPages: 1, totalElements: 0 })

  useEffect(() => {
    fetchLifeHabitsAndBehaviorHistory({ offset: 0 })
  }, [])

  const fetchLifeHabitsAndBehaviorHistory = async (params={}) => {
    params = { ...params, "active": true, "person__id": person?.id }
    
    try {
      let res = await getLifeHabitsAndBehavior(params)
      setLifeHabitsAndBehaviorHistory(res.data.results)
      setPagination({
        totalElements: res.data.count,
        totalPages: Math.ceil(res.data.count / 10),
        pageNumber: params.offset / 10,
      })
    } catch (err) {
      dispatch(AddAlert('Hábitos de Vida e Comportamento', 'Falha ao obter hábitos de vida e comportamento', AlertType.ERROR))
      console.error('Hábitos de Vida e Comportamento ~ fetchLifeHabitsAndBehaviorHistory ~ ', err)
    }
  }

  const extraAction = async () => {
    await fetchLifeHabitsAndBehaviorHistory({ offset: 0, active: true })
    setModalInfo(MODAL_INIT_PROPS)
  }
  
  const openNewEditShowLifeHabitsAndBehaviorModal = (cursor) => {
    setModalInfo(prev => ({
      ...prev,
      open: true,
      title: 'Hábitos de Vida e Comportamento',
      content: <NewEditLifeHabitsAndBehavior
        initLifeHabitsAndBehavior={cursor}
        person={person}
        attendanceID={attendanceID}
        extraAction={extraAction} 
      />
    }))
  }
  
  const openDeleteLifeHabitsAndBehaviorModal = (cursor) => {
    setModalInfo(prev => ({
      ...prev,
      open: true,
      title: 'Deleção de Hábitos de Vida e Comportamento',
      content: <DeleteLifeHabitsAndBehavior
        life_habits_and_behavior={cursor} 
        extraAction={extraAction}
      />
    }))
  }

  const sections = {
    'ShowLifeHabitsAndBehavior': <ShowLifeHabitsAndBehavior 
      lifeHabitsAndBehaviorHistory={lifeHabitsAndBehaviorHistory}
      fetchLifeHabitsAndBehaviorHistory={fetchLifeHabitsAndBehaviorHistory}
      setModalInfo={setModalInfo} 
    />,
    'TableLifeHabitsAndBehavior': <TableLifeHabitsAndBehavior 
      lifeHabitsAndBehaviorHistory={lifeHabitsAndBehaviorHistory}
      fetchLifeHabitsAndBehaviorHistory={fetchLifeHabitsAndBehaviorHistory} 
      openNewEditShowLifeHabitsAndBehaviorModal={openNewEditShowLifeHabitsAndBehaviorModal}
      openDeleteLifeHabitsAndBehaviorModal={openDeleteLifeHabitsAndBehaviorModal}
      pagination={pagination}
    />
  }

  return (
    <div>
      <Modal {...modalInfo} dismissFn={() => setModalInfo(MODAL_INIT_PROPS)} />
      <div className='LifeHabitsAndBehavior-ActionRow'>
        <div
          onClick={() => openNewEditShowLifeHabitsAndBehaviorModal()}
          title='Novo hábito de vida e comportamento'
        >
          <NewTaskIcon />
        </div>
        {openSection === 'ShowLifeHabitsAndBehavior' ? (
          <div onClick={() => setOpenSection('TableLifeHabitsAndBehavior')} title='Ver Dados'>
            <ListIcon />
          </div>
        ) : (
          <div onClick={() => setOpenSection('ShowLifeHabitsAndBehavior')} title='Ver Gráficos'>
            <ChartIcon />
          </div>
        )}
      </div>
      <div>
        { sections[openSection] }
      </div>
    </div>
  );
}
