import './FilledScheduleMenu.css'
import React, { useState } from "react";
import { useHistory } from 'react-router-dom'
import { IcoBin, IcoEstetoscopio, MaskDataHora } from '../../../../../../../../../../../../component';
import IcoQuickly from '../../../../../../../../../../../../component/icon/quickly';
import { useBookingAppointmentCursor } from '../../../../../../BookingAppointmentCursor';
import AssignScheduleToPatient from '../../../AssignScheduleToPatient/AssignScheduleToPatient';
import DeleteScheduleSlot from '../../../DeleteScheduleSlot/DeleteScheduleSlot';
import IcoBlockCalendar from '../../../../../../../../../../../../component/icon/calendar_block';
import { useApp } from '../../../../../../../../../../../../layout/App';
import { IcoEditCalendar } from '../../../../../../../../../../../../component/icon/editcalendar';
import MarkTimeAsUnavailable from '../../../MarkTimeAsUnavailable/MarkTimeAsUnavailable';
import MarkTimeAsAvailable from '../../../MarkTimeAsAvailable/MarkTimeAsAvailable';
import IcoCalendarUnblock from '../../../../../../../../../../../../component/icon/calendar_unblock';
import { patchAttendance } from '../../../../../../../../../../http';

const defaultOptions = require('../../../../../../../../../../../../component/form/select/options.json')

export default function FilledScheduleMenu({ appointment={}, displayMode='daily' }) {
    const history = useHistory()
    const [isOpen, setIsOpen] = useState(false);
    const { setModalInfo, setSelectedTimesCursor, selectedTimesCursor } = useBookingAppointmentCursor()
    const startHourTime = MaskDataHora(appointment?.fields?.start || appointment?.start, 'DD/MMM/AAAA', '-');
    const endHourTime = MaskDataHora(appointment?.fields?.end || appointment?.end, 'DD/MMM/AAAA', '-');
    const patient = appointment?.user || appointment?.user?.[0] || {};
    const { isHealthProfessional } = useApp()
    const isChecked = !!selectedTimesCursor.find(({pk}) => pk === appointment.pk)
    const appointmentDuration = new Date(appointment.fields.end) - new Date(appointment.fields.start)
    const isSaturday = new Date(appointment.fields.start).getDay() === 6;

    const openAssignScheduleToPatient = () => {
      const start = appointment?.fields?.start?.slice(11,16) || appointment?.start?.slice(11,16) || '-'
      const end = appointment?.fields?.end?.slice(11,16) || appointment?.end?.slice(11,16) || '-'

      setModalInfo(prev => ({ 
          ...prev, 
          open: true,
          title: `Editar horário | ${startHourTime?.data} | ${start} - ${end}`,
          content: <AssignScheduleToPatient initSchedulingInfo={appointment} setModalInfo={setModalInfo} />
      }))
    }

    const openRemoveSchedule = () => {
      setModalInfo(prev => ({
          ...prev,
          open: true,
          title: 'Remover Horário(s)',
          content: <DeleteScheduleSlot appointments={[appointment]} />
      }))
    }

    const handleBlockSchedule = async () => {
      setModalInfo(prev => ({
        ...prev,
        open: true,
        title: 'Bloquear Horário(s)',
        content: <MarkTimeAsUnavailable appointments={[appointment]} />
      }))
    }
    
    const handleMoveToAvailable = async () => {
      setModalInfo(prev => ({
        ...prev,
        open: true,
        title: 'Disponibilizar Horário(s)',
        content: <MarkTimeAsAvailable appointments={[appointment]} />
      }))
    }

    const patchAttendanceStatus = async (attendanceId) => {
      const payload = {"decision": "OPEN"}
      await patchAttendance(attendanceId, payload)
    }

    const openConsultation = async () => {
      if (isHealthProfessional) {
        await patchAttendanceStatus(appointment?.attendance?.id)
        history.push(`/consultation/${appointment?.attendance?.id}`)
      }
    }

    const attendanceResult = appointment?.attendance?.result
      ? ` | ${defaultOptions.RESULT_CHOICES.find(({ id }) => id === appointment.attendance.result)?.name || ''}`
      : null;
    const attendanceClassification = appointment?.attendance?.classification
      ? ` | ${defaultOptions.CLASSIFICATION_CHOICES.find(({ id }) => id === appointment.attendance.classification)?.name || ''}`
      : null;
    const attendanceDecision = appointment?.attendance?.decision
      ? `${defaultOptions.DECISION_CHOICES.find(({ id }) => id === appointment.attendance.decision)?.name || ''}`
      : null;
    const attendancePriority = appointment?.attendance?.priority
      ? ` | ${defaultOptions.PRIORITY_CHOICES.find(({ id }) => id === appointment.attendance.priority)?.name || ''}`
      : null
    const attendanceHealthInsurance = appointment?.attendance?.health_insurance?.name
      ? ` | ${appointment?.attendance?.health_insurance?.name}`
      : null
    const attendanceSpecialty = appointment?.attendance?.specialty?.name
      ? ` | ${appointment?.attendance?.specialty?.name}`
      : null

    let initDropdownOptions = [
        { 
          title: 'Abrir Atendimento', 
          Icon: <IcoEstetoscopio style={{ width: '14px', height: '14px' }} className='' />,
          action: openConsultation,
        },
        { 
          title: 'Editar Horário', 
          Icon: <IcoEditCalendar style={{ width: '16px', height: '16px' }} />,
          action: openAssignScheduleToPatient,
        },
        { 
          title: 'Disponibilizar Horário', 
          Icon: <IcoCalendarUnblock style={{ width: '14px', height: '14px' }} />,
          action: handleMoveToAvailable,
        },
        { 
          title: 'Excluir Horário',
          Icon: <IcoBin style={{ width: '14px', height: '14px' }} className='' />,
          action: openRemoveSchedule
        },
        { 
          title: 'Bloquear Horário', 
          Icon: <IcoBlockCalendar style={{ width: '14px', height: '14px' }} className='' />,
          action: handleBlockSchedule
        }
    ]

    let dropdownOptions = initDropdownOptions
    if (!isHealthProfessional) {
      dropdownOptions = initDropdownOptions.filter(({title}) => title != 'Abrir Atendimento')
    }

    return <div 
      className='FilledScheduleMenu'
      style={displayMode === 'weekly' ? (appointmentDuration < 1500000 ? {fontSize: '10px'} : {fontSize: '12px'}) : {fontSize: '14px'}}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
        <div className='FilledScheduleMenu-Content'>
          <div className='FilledScheduleMenu-TitleDiv'>
            <span>
              <strong>{attendanceDecision}</strong>&nbsp;
              {displayMode==='daily' && appointmentDuration <= 1500000 && <span style={{ fontSize: '12px' }}>{`| ${startHourTime?.hora?.slice(0,5) || '-'} | ${endHourTime?.hora?.slice(0,5) || '-'}`}</span>}
              {displayMode==='weekly' ? '' : (<> | {patient.social_name || patient.name || 'Nome não cadastrado'}{attendancePriority}{attendanceSpecialty}</>)}
            </span>
            {displayMode==='weekly' && (
              <input
              type='checkbox'
              className='FilledScheduleMenu-checkbox'
              checked={isChecked}
              onChange={
                () => {
                  isChecked 
                  ? setSelectedTimesCursor(prev => prev.filter(({pk}) => appointment.pk != pk))
                  : setSelectedTimesCursor(prev => ([ ...prev, appointment ]))}
                }
            />
            )}
          </div>
          {(displayMode==='weekly' && appointmentDuration > 2100000) || (displayMode==='daily' && appointmentDuration > 1500000) && (
            <div className='FilledScheduleMenu-HourTime'>
              <IcoQuickly style={displayMode === 'daily' ? { fontSize: '16px' } : { fontSize: '14px' }} /> 
              <span style={displayMode === 'daily' ? { fontSize: '12px' } : { fontSize: '10px' }}>{startHourTime?.hora?.slice(0,5) || '-'} | {endHourTime?.hora?.slice(0,5) || '-'}</span>
            </div>
          )}
        </div>
        <div className={`FilledScheduleMenu-Dropdown ${(displayMode === 'weekly' & isSaturday) ? 'FilledScheduleMenu-Dropdown-left' : ''} ${displayMode === 'weekly' ? 'FilledScheduleMenu-Dropdown-content-weekly' : ''}`}>
          {isOpen && (
            <div className="FilledScheduleMenu-Dropdown-content">
              <div className='FilledScheduleMenu-Dropdown-ActionBar'>
                {dropdownOptions.map((option, index) => (
                  <div
                    key={index}
                    title={option.title}
                    onClick={option.action} 
                    className="FilledScheduleMenu-Dropdown-item"
                  >
                    {option.Icon} {option.label}
                  </div>
                ))}
              </div>
              {displayMode === 'weekly' && (
                <div className='FilledScheduleMenu-DropDown-info'>
                  <p>{<div className='FilledScheduleMenu-HourTime'>
                        <IcoQuickly style={{fontSize: '16px'}} /> 
                        <span style={{fontSize: '12px'}}>{startHourTime?.hora?.slice(0,5) || '-'} | {endHourTime?.hora?.slice(0,5) || '-'}</span>
                      </div>}
                  </p>
                  <p>{patient.social_name || patient.name || 'Nome não cadastrado'}</p>
                  <p>{attendancePriority?.replace(/\|/g, '').trim() === 'Sem prioridade' ?  (`${attendancePriority?.replace(/\|/g, '')}`) : (`Prioridade ${attendancePriority?.replace(/\|/g, '')}`)}</p>
                  <p>{(attendanceSpecialty ? `${attendanceSpecialty?.replace(/\|/g, '')}` : 'Sem especialidade')}</p>
                </div>
              )}
            </div>
          )}
        </div>
    </div>
}