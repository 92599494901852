import './Chat.css'
import React, { useState, useRef, useEffect } from 'react';
import ChatWindow from './ChatWindow/ChatWindow';
import ChatIcon from '../../../../component/icon/chat';
import { ExclamationIco } from '../../../../component';
import { useApp } from '../../../../layout/App';
import { getLastMessageRead } from '../../http';


export default function Chat(onlineUsers) {
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
    const [lastReadMessages, setLastReadMessages] = useState([]);
    const { currentHealthPlaceUser } = useApp();

    const pendingNotificationQueue = useRef([])
    const reconnectInterval = useRef(null)
    const intentionalDisconnect = useRef(false)
    const ws = useRef(null)

    
    const toggleChat = () => {
        setIsChatOpen(!isChatOpen);
    };

    const fetchLastReadMessages = async () => {
        try {            
            const res = await getLastMessageRead(currentHealthPlaceUser.user.id);
            setLastReadMessages(res.data);
            setHasUnreadMessages(res.data.length > 0);
        } catch (error) {
            console.error("Erro ao carregar mensagens lidas:", error);
        }
    };

    const notificationEvent = new CustomEvent('chatNotification', {
        detail: { message: 'Nova mensagem recebida'}
    });

    const sendNotification = (notification) => {
        const payload = {
            ...notification,
            type: "send_notification",
        }

        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
            ws.current.send(JSON.stringify(payload));
        } else {
            pendingNotificationQueue.current.push(payload);
        }
    }

    const onMessagesUpdated = () => {
        fetchLastReadMessages();
    };

    useEffect(() => {

        const connectWebSocket = () => {

            if (ws.current && ws.current.readyState === WebSocket.OPEN) return;

            const wsScheme = process.env.NODE_ENV === "production" ? "wss" : "ws";
            const wsHost = process.env.REACT_APP_SALUTHO_WEBSOCKET_HOST
                ? process.env.REACT_APP_SALUTHO_WEBSOCKET_HOST
                : window.location.hostname;

            let pingInterval;

            if (ws.current) {
                ws.current.close();
            }

            if (localStorage.token) {

                ws.current = new WebSocket(`${wsScheme}://${wsHost}/ws/chat/notification/?authorization=Token ${localStorage.token}`)

                ws.current.onopen = () => {
                    console.log('WebSocket connected')

                    pingInterval = setInterval(() => {
                        if (ws.current.readyState === WebSocket.OPEN) {
                            ws.current.send(JSON.stringify({ type: 'ping' }))
                        }
                    }, 10000)

                    while (pendingNotificationQueue.current.length > 0) {
                        const notification = pendingNotificationQueue.current.shift()
                        ws.current.send(JSON.stringify(notification))
                    }
                }

                ws.current.onmessage = (event) => {
                    const data = JSON.parse(event.data);

                    if (data.type === "send_notification") {
                        setLastReadMessages((prev) => {
                            const chatIndex = prev.findIndex((info) => info.chat_id === data.chat_id);

                            const updatedInfo = [...prev]

                            if (chatIndex >= 0) {
                                updatedInfo[chatIndex] = {
                                    ...prev[chatIndex],
                                    unread_count: data.unread_count,
                                };
                            } else {
                                updatedInfo.push({
                                    chat_id: data.chat_id,
                                    unread_count: data.unread_count,
                                    last_read_message: data.last_read_message
                                });
                            }
                            window.dispatchEvent(notificationEvent);
                            return updatedInfo
                        })
                    } else if (data.type === 'pong') {
                        console.log('Received pong - ChatNotification')
                    }
                }

                ws.current.onerror = (error) => {
                    console.error('WebSocket error:', error)
                    clearInterval(pingInterval)
                }

                ws.current.onclose = () => {
                    console.log('WebSocket disconnected, reconnecting...')
                    clearInterval(pingInterval)

                    if (!intentionalDisconnect.current) {
                        reconnectInterval.current = setTimeout(connectWebSocket, 5000);
                    }
                }
            }
        }

        connectWebSocket()

        return () => {
            if (ws.current) {
                ws.current.close();
            }
            if (reconnectInterval.current) {
                clearTimeout(reconnectInterval.current);
            }
        };
    }, []);
    

    useEffect(() => {
        fetchLastReadMessages(); 
    }, []);

    return (
        <>
            <div className="box-container-body-header-chat-btn-box">
                <button
                    className="box-container-body-header-chat-btn"
                    onClick={toggleChat}
                    title="Conversas"
                >
                    { hasUnreadMessages && <div className="box-container-body-header-chat-notification">
                        <ExclamationIco />
                    </div>}
                    <ChatIcon />
                </button>
            </div>
            <ChatWindow isOpen={isChatOpen} setIsOpen={setIsChatOpen} onlineUsers={onlineUsers} lastReadMessages={lastReadMessages} setLastReadMessages={setLastReadMessages} onMessagesUpdated={onMessagesUpdated} sendNotification={sendNotification}/>
        </>
    );
}
