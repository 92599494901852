import './Anthropometry.css';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ChartIcon from '../../../../../../../../component/icon/chart';
import ListIcon from '../../../../../../../../component/icon/list';
import NewTaskIcon from '../../../../../../../../component/icon/new_task';
import Modal, { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal';
import { AddAlert, AlertType, MaskDataHora } from '../../../../../../../../component';
import TableAnthropometry from './subcomponents/TableAnthropometry/TableAnthropometry'
import ChartAnthropometry from './subcomponents/ChartAnthropometry/ChartAnthropometry'
import NewEditAnthropometry from './subcomponents/NewEditAnthropometry/NewEditAnthropometry';
import DeleteAnthropometry from './subcomponents/DeleteAnthropometry/DeleteAnthropometry';
import { getAnthropometry } from '../../../../../../http';

export default function Anthropometry({ person, attendanceID=null, }) {
  const dispatch = useDispatch()
  const [anthropometries, setAnthropometries] = useState([])
  const [openSection, setOpenSection] = useState('TableAnthropometry')
  const [modalInfo, setModalInfo] = useState(MODAL_INIT_PROPS)
  const [pagination, setPagination] = useState({ pageNumber: 0, totalPages: 1, totalElements: 0 })

  useEffect(() => {
    fetchAnthropometies({ offset: 0 })
  }, [])

  const fetchAnthropometies = async (params={}) => {
    params = { ...params, "active": true, "person__id": person?.id }
    
    try {
      let res = await getAnthropometry(params)
      setAnthropometries(res.data.results)
      setPagination({
        totalElements: res.data.count,
        totalPages: Math.ceil(res.data.count / 10),
        pageNumber: params.offset / 10,
      })
    } catch (err) {
      dispatch(AddAlert('Antropometria', 'Falha ao obter sinais antropometria', AlertType.ERROR))
      console.error('Antropometria ~ fetchAnthropometies ~ ', err)
    }
  }

  const extraAction = async () => {
    await fetchAnthropometies({ offset: 0, active: true })
    setModalInfo(MODAL_INIT_PROPS)
  }

  const openNewEditAnthropometryModal = (cursor) => {
    setModalInfo(prev => ({
      ...prev,
      open: true,
      title: 'Antropometria',
      content: <NewEditAnthropometry
        initAnthropometry={cursor}
        person={person}
        attendanceID={attendanceID}
        extraAction={extraAction} 
      />
    }))
  }

  const openDeleteAnthropometryModal = (cursor) => {
    setModalInfo(prev => ({
      ...prev,
      open: true,
      title: 'Deleção de Antropometria',
      content: <DeleteAnthropometry
        anthropometry={cursor} 
        extraAction={extraAction}
      />
    }))
  }

  const sections = {
    'ChartAnthropometry': <ChartAnthropometry 
      anthropometries={anthropometries}
      fetchAnthropometies={fetchAnthropometies}
      setModalInfo={setModalInfo} 
    />,
    'TableAnthropometry': <TableAnthropometry 
      anthropometries={anthropometries}
      fetchAnthropometies={fetchAnthropometies} 
      openNewEditAnthropometryModal={openNewEditAnthropometryModal}
      openDeleteAnthropometryModal={openDeleteAnthropometryModal}
      pagination={pagination}
    />
  }

  return (
    <div>
      <Modal {...modalInfo} dismissFn={() => setModalInfo(MODAL_INIT_PROPS)} />
      <div className='Anthropometry-ActionRow'>
        <div
          onClick={() => openNewEditAnthropometryModal()}
          title='Nova Antropometria'
        >
          <NewTaskIcon />
        </div>
        {openSection === 'ChartAnthropometry' ? (
          <div onClick={() => setOpenSection('TableAnthropometry')} title='Ver Dados'>
            <ListIcon />
          </div>
        ) : (
          <div onClick={() => setOpenSection('ChartAnthropometry')} title='Ver Gráficos'>
            <ChartIcon />
          </div>
        )}
      </div>
      <div>
        { sections[openSection] }
      </div>
    </div>
  );
}
