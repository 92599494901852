import './TableElimination.css'
import React, { useEffect } from 'react'
import { IcoBin, IcoPencil, List, MaskDataHora, Paginate } from '../../../../../../../../../../component';

const defaultOptions = require('../../../../../../../../../../component/form/select/options.json')

export default function TableElimination({ eliminationsHistory=[], fetchEliminationsHistory= async () => null, openNewEditEliminationsModal=() => null, openDeleteEliminationsModal=() => null, pagination={} }) {
    useEffect(() => {
        fetchEliminationsHistory({ offset: 0, limit: 10 })
    }, [])

    const handleNextPage = async e => {
        let offset = e * 10
		await fetchEliminationsHistory({ "offset": offset, limit: 10 })
	}
    
    const headDefault = [
        { "colunm": "h_urinary_elimination", "text": "Eliminação urinária" },
        { "colunm": "h_intestinal_elimination", "text": "Eliminação intestinal" },
        { "colunm": "h_elimination_observations", "text": "Observações" },
        { "colunm": "h_updated_at", "text": "Últ. alt.", "width": "100px" },
        { "colunm": "h_appointment_date", "text": "Data de Atend.", "width": "100px" },
        { "colunm": "h_action", "text": <div className='TableElimination-HeaderRow-Action'>Ações</div>, width: '72px' } 
    ]

    return <div>
        <div className='TableElimination-Body-List'>
            <List
                head={headDefault}
                data={eliminationsHistory}
                listCustom={cursor => {
                    const renderTranslatedFieldNormalOrNoNormal = (selected) => {
                        return defaultOptions.ONLY_NORMAL_NO_NORMAL_OR_NOT_EVALUATED.find(({id}) => id === selected)?.name  || '-'
                    }

                    const custom = cursor;

                    custom['h_urinary_elimination'] = renderTranslatedFieldNormalOrNoNormal(cursor?.urinary_elimination)
                    custom['h_intestinal_elimination'] = renderTranslatedFieldNormalOrNoNormal(cursor?.intestinal_elimination)
                    custom['h_elimination_observations'] = cursor?.elimination_observations 
                        ? <p dangerouslySetInnerHTML={{ __html: cursor?.elimination_observations }}></p> 
                        : '-'
                    custom['h_updated_at'] = MaskDataHora(cursor?.updated_at)?.data || '-'
                    custom['h_appointment_date'] = MaskDataHora(cursor?.attendance?.start)?.data || '-'
                    custom['h_action'] = (
                        <div className='TableElimination-ActionRow'>
                            <div
                                title='Editar Eliminação'
                            	onClick={() => openNewEditEliminationsModal(cursor)}
                            >
                            	<IcoPencil />
                            </div>
                            <div
                                title='Excluir Eliminação'
                                onClick={() => openDeleteEliminationsModal(cursor)}
                            >
                                <IcoBin />
                            </div>
                        </div>
                    )
                    return custom
                }}
            />
            <div className='TableElimination-Pagination'>
                <Paginate data={pagination} action={handleNextPage} />
            </div>
        </div>
        <div className='TableElimination-Legend'>
            <span>Legenda:</span>
            <ul>
                <li><strong>Últ. alt.</strong> - Última Alteração</li>
                <li><strong>Data Atend.</strong> - Data de Atendimento</li>
            </ul>
        </div>
    </div>
}