import './TableVitalSigns.css'
import React, { useEffect, useState } from 'react'
import { IcoBin, IcoPencil, List, MaskDataHora, Paginate } from '../../../../../../../../../../component';
import { Mask1DecimalPlace } from '../../../../../../../../../../component/mask/mask';


export default function TableVitalSigns({ vitalSigns=[], pagination={}, fetchVitalSigns=async () => null, openNewEditVitalSignModal=() => null, openDeleteVitalSignModal=() => null }) {
    useEffect(() => {
        fetchVitalSigns({ offset: 0, limit: 10 })
    }, [])

    const handleNextPage = async e => {
        let offset = e * 10
		await fetchVitalSigns({ "offset": offset, limit: 10 })
	}
                                    
    const headDefault = [
        { "colunm": "h_systolic", "text": "Sist. (mmHg)" },
        { "colunm": "h_diastolic", "text": "Diast. (mmHg)" },
        { "colunm": "h_oxygen_saturation", "text": "Sat. O2 (%)" },
        { "colunm": "h_body_temperature", "text": "Temp. Corp. (°C)" },
        { "colunm": "h_heart_rate", "text": "Freq. Card. (bpm)" },
        { "colunm": "h_respiration_rate", "text": "Freq. Resp. (mpm)" },
        { "colunm": "h_updated_at", "text": "Últ. alt.", "width": "100px" },
        { "colunm": "h_appointment_date", "text": "Data de Atend.", "width": "100px" },
        { "colunm": "h_action", "text": <div className='TableVitalSigns-HeaderRow-Action'>Ações</div>, width: '72px' } 
    ]

    return <div>
        <div className='TableVitalSigns-Body-List'>
            <List
                head={headDefault}
                data={vitalSigns}
                listCustom={cursor => {
                    const custom = cursor;
                    custom['h_systolic'] = cursor?.systolic || '-'
                    custom['h_diastolic'] = cursor?.diastolic || '-'
                    custom['h_oxygen_saturation'] = cursor?.oxygen_saturation ? Mask1DecimalPlace(cursor?.oxygen_saturation) : '-'
                    custom['h_body_temperature'] = cursor?.body_temperature ? Mask1DecimalPlace(cursor?.body_temperature) : '-'
                    custom['h_heart_rate'] = cursor?.heart_rate || '-'
                    custom['h_respiration_rate'] = cursor?.respiration_rate || '-'
                    custom['h_updated_at'] = MaskDataHora(cursor?.updated_at)?.data || '-'
                    custom['h_appointment_date'] = MaskDataHora(cursor?.attendance?.start)?.data || '-'
                    custom['h_action'] = (
                        <div className='TableVitalSigns-ActionRow'>
                            <div
                                title='Editar Sinal Vital'
                            	onClick={() => openNewEditVitalSignModal(cursor)}
                            >
                            	<IcoPencil />
                            </div>
                            <div
                                title='Excluir Sinal Vital'
                                onClick={() => openDeleteVitalSignModal(cursor)}
                            >
                                <IcoBin />
                            </div>
                        </div>
                    )
                    return custom
                }}
            />
            <div className='TableVitalSigns-Pagination'>
                <Paginate data={pagination} action={handleNextPage} />
            </div>
        </div>
        <div className='TableVitalSigns-Legend'>
            <span>Legenda:</span>
            <ul>
                <li><strong>Sist. (mmHg)</strong> - Sistólica (mmHg)</li>
                <li><strong>Diast. (mmHg)</strong> - Diastólica (mmHg)</li>
                <li><strong>Sat. O2 (%)</strong> - Saturação de oxigênio (%)</li>
                <li><strong>Temp. Corp. (°C)</strong> - Temperatura corporal (°C)</li>
                <li><strong>Freq. Card. (bpm)</strong> - Frequência cardíaca (bpm)</li>
                <li><strong>Freq. Resp. (mpm)</strong> - Frequência respiratória (mpm)</li>
                <li><strong>Últ. alt.</strong> - Última Alteração</li>
                <li><strong>Data Atend.</strong> - Data de Atendimento</li>
            </ul>
        </div>
    </div>
}