import React from 'react';
import './NewPaginate.css';

export default function NewPaginate({ currentPage, totalPages, onPageChange }) {
  const handlePrev = () => {
    if (currentPage > 0) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages - 1) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className="NewPaginate">
      <button 
        onClick={handlePrev} 
        disabled={currentPage === 0}
      >
        &laquo;
      </button>

      <span>
        Página {currentPage + 1} de {totalPages}
      </span>

      <button 
        onClick={handleNext} 
        disabled={currentPage === totalPages - 1}
      >
        &raquo;
      </button>
    </div>
  );
}
