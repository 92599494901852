import './ChartAnthropometry.css';
import React, { useEffect } from 'react';
import {
  Chart,
  LinearScale,
  CategoryScale,
  LineController,
  PointElement,
  LineElement,
  Title,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import ShowDetailChartAnthropometry from '../ShowDetailChartAnthropometry/ShowDetailChartAnthropometry';
import { MaskDataHora } from '../../../../../../../../../../component';

Chart.register(
  LinearScale,
  CategoryScale,
  LineController,
  PointElement,
  LineElement,
  Title
);

export default function ChartAnthropometry({ anthropometries = [], fetchAnthropometies=() => null, setModalInfo = () => null }) {
  useEffect(() => {
    fetchAnthropometies({ offset: 0 })
  }, [])

  const anthroLabels = anthropometries.map((record) => record.date || MaskDataHora(record?.updated_at)?.data);

  const weightData = anthropometries
    .filter((record) => record.weight)
    .map((record) => record.weight || null);
  const heightData = anthropometries
    .filter((record) => record.height)
    .map((record) => record.height || null);
  const abdominalCircData = anthropometries
    .filter((record) => record.height)
    .map((record) => record.abdominal_circumference || null);

  const anthropometryCharts = [
    {
      title: 'Peso',
      data: weightData,
      labels: anthroLabels,
      yLabel: 'Peso (kg)',
      color: 'rgba(0,0,255,1)',
    },
    {
      title: 'Altura',
      data: heightData,
      labels: anthroLabels,
      yLabel: 'Altura (cm)',
      color: 'rgba(0,255,0,1)',
    },
    {
      title: 'Circunferência Abdominal',
      data: abdominalCircData,
      labels: anthroLabels,
      yLabel: 'Circunferência (cm)',
      color: 'rgba(255,0,0,1)',
    },
  ];

  const handleShowDetailsModal = (chartConfig) => {
    setModalInfo((prev) => ({
      ...prev,
      open: true,
      title: 'Mostrar Detalhes',
      content: <ShowDetailChartAnthropometry chartConfig={chartConfig} />,
    }));
  };

  return (
    <div className='ChartAnthropometry-Grid-Content'>
      {anthropometryCharts.map((chartConfig, index) => {
        const config = {
          data: {
            labels: chartConfig.labels,
            datasets: [
              {
                label: chartConfig.title,
                data: chartConfig.data,
                borderColor: chartConfig.color,
                backgroundColor: chartConfig.color.replace('1)', '0.1)'),
                fill: true,
                tension: 0.1,
                spanGaps: true,
              },
            ],
          },
          options: {
            responsive: true,
            plugins: {
              title: {
                display: true,
                text: '',
              },
            },
            scales: {
              x: {
                title: {
                  display: true,
                  text: 'Data',
                },
              },
              y: {
                title: {
                  display: true,
                  text: chartConfig.yLabel,
                },
              },
            },
          },
        };
        return (
          <div
            className='ChartAnthropometry-Grid-Chart-Container'
            key={index}
            onClick={() => handleShowDetailsModal(chartConfig)}
          >
            <div className='ChartAnthropometry-ChartOverlay'>
              <p>Mostrar Detalhes</p>
            </div>
            <Line data={config.data} options={config.options} />
          </div>
        );
      })}
    </div>
  );
}
