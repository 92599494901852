import './StaticRefraction.css'
import React from 'react'
import { handleAxis, handleCDegree, handleSignal } from '../helpers'
import { Input, maskSnellenChart } from '../../../../../../../../../../component'



export default function StaticRefraction({
    handleChange, 
    holdingOphthalmology, 
    holdingOphthalmologyErr,
    isEditable=true
}) {
    return <div>
        <p><b>Refração Estática</b></p>
        <div className='StaticRefraction-Header'>
            <span></span>
            <span>Grau E</span>
            <span>Grau C</span>
            <span>Eixo</span>
            <span>Visão</span>

        </div>

        <div className='StaticRefraction-Grid'>
            <span>OD</span>
            <Input
                name='right_eye_static_refraction_e_degree'
                action={event => handleChange(event)}
                actionBlur={event => handleChange(event)}
                value={handleSignal(holdingOphthalmology?.right_eye_static_refraction_e_degree)}
                errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                disabled={!isEditable}
            />
            <Input
                name='right_eye_static_refraction_c_degree'
                action={event => handleChange(event)}
                actionBlur={event => handleChange(event)}
                value={handleCDegree(holdingOphthalmology?.right_eye_static_refraction_c_degree)}
                errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                disabled={!isEditable}
            />
            <Input
                name='right_eye_static_refraction_axis'
                action={event => handleChange(event)}
                actionBlur={event => handleChange(event)}
                value={handleAxis(holdingOphthalmology?.right_eye_static_refraction_axis)}
                type='Number'
                errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                disabled={!isEditable}
            />
            <Input
                name='right_eye_static_refraction_vision'
                action={event => handleChange(event)}
                actionBlur={event => handleChange(event)}
                value={maskSnellenChart(holdingOphthalmology?.right_eye_static_refraction_vision)}
                errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                disabled={!isEditable}
            />
            <span>OE</span>
            <Input 
                name='left_eye_static_refraction_e_degree'
                action={event => handleChange(event)}
                actionBlur={event => handleChange(event)}
                value={handleSignal(holdingOphthalmology?.left_eye_static_refraction_e_degree)}
                errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                disabled={!isEditable}
            />
            <Input
                name='left_eye_static_refraction_c_degree'
                action={event => handleChange(event)}
                actionBlur={event => handleChange(event)}
                value={handleCDegree(holdingOphthalmology?.left_eye_static_refraction_c_degree)}
                errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                disabled={!isEditable}
            />
            <Input
                name='left_eye_static_refraction_axis'
                action={event => handleChange(event)}
                actionBlur={event => handleChange(event)}
                value={handleAxis(holdingOphthalmology?.left_eye_static_refraction_axis)}
                type='Number'
                errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                disabled={!isEditable}
            />
            <Input
                name='left_eye_static_refraction_vision'
                action={event => handleChange(event)}
                actionBlur={event => handleChange(event)}
                value={maskSnellenChart(holdingOphthalmology?.left_eye_static_refraction_vision)}
                errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                disabled={!isEditable}
            />
        </div>
    </div>
}