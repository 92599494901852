import './TableChronicCondition.css'
import React, { useEffect } from 'react'
import { IcoBin, IcoPencil, List, MaskDataHora, Paginate } from '../../../../../../../../../../component';

const defaultOptions = require('../../../../../../../../../../component/form/select/options.json')

export default function TableChronicCondition({ chronicConditionsHistory=[], fetchChronicConditions= async () => null, openNewEditChronicConditionModal=() => null, openDeleteChronicConditionModal=() => null, pagination={} }) {
    useEffect(() => {
        fetchChronicConditions({ offset: 0, limit: 10 })
    }, [])

    const handleNextPage = async e => {
        let offset = e * 10
		await fetchChronicConditions({ "offset": offset, limit: 10 })
	}
    
    const headDefault = [
        { "colunm": "h_diabetes", "text": "Diab." },
        { "colunm": "h_diabetes_insulin", "text": "Insul." },
        { "colunm": "h_hypertension", "text": "Hipert." },
        { "colunm": "h_heart_disease", "text": "Cárd." },
        { "colunm": "h_high_total_cholesterol", "text": "Colest." },
        { "colunm": "h_high_triglycerides", "text": "Triglic." },
        { "colunm": "h_musculoskeletal", "text": "Musc." },
        { "colunm": "h_updated_at", "text": "Últ. alt." },
        { "colunm": "h_appointment_date", "text": "Data de Atend." },
        { "colunm": "h_action", "text": <div className='TableChronicCondition-HeaderRow-Action'>Ações</div>, width: '72px' } 
    ]


    return <div>
        <div className='TableChronicCondition-Body-List'>
            <List
                head={headDefault}
                data={chronicConditionsHistory}
                listCustom={cursor => {
                    const renderTranslatedField = (selected) => {
                        return defaultOptions.ONLY_YES_NO_OR_NOT_EVALUATED.find(({id}) => id === selected)?.name  || '-'
                    }
                    const custom = cursor;

                    custom['h_diabetes'] = renderTranslatedField(cursor?.diabetes)
                    custom['h_diabetes_insulin'] = renderTranslatedField(cursor?.diabetes_insulin)
                    custom['h_hypertension'] = renderTranslatedField(cursor?.hypertension)
                    custom['h_heart_disease'] = renderTranslatedField(cursor?.heart_disease)
                    custom['h_high_total_cholesterol'] = renderTranslatedField(cursor?.high_total_cholesterol)
                    custom['h_high_triglycerides'] = renderTranslatedField(cursor?.high_triglycerides)
                    custom['h_musculoskeletal'] = renderTranslatedField(cursor?.musculoskeletal)
                    custom['h_updated_at'] = MaskDataHora(cursor?.updated_at)?.data || '-'
                    custom['h_appointment_date'] = MaskDataHora(cursor?.attendance?.start)?.data || '-'
                    custom['h_action'] = (
                        <div className='TableChronicCondition-ActionRow'>
                            <div
                                title='Editar Condição Crônica'
                            	onClick={() => openNewEditChronicConditionModal(cursor)}
                            >
                            	<IcoPencil />
                            </div>
                            <div
                                title='Excluir Condição Crônica'
                                onClick={() => openDeleteChronicConditionModal(cursor)}
                            >
                                <IcoBin />
                            </div>
                        </div>
                    )
                    return custom
                }}
            />
            <div className='TableChronicCondition-Pagination'>
                <Paginate data={pagination} action={handleNextPage} />
            </div>
        </div>
        <div className='TableChronicCondition-Legend'>
            <span>Legenda:</span>
            <ul>
                <li><strong>Diab.</strong> - Diabetes</li>
                <li><strong>Insul.</strong> - Uso de Insulina</li>
                <li><strong>Hipert.</strong> - Hipertensão</li>
                <li><strong>Cárd.</strong> - Doenças Cardíacas</li>
                <li><strong>Colest.</strong> - Colesterol Alto</li>
                <li><strong>Triglic.</strong> - Triglicerídeos Altos</li>
                <li><strong>Musc.</strong> - Problemas Musculoesqueléticos</li>
                <li><strong>Últ. alt.</strong> - Última Alteração</li>
                <li><strong>Data Atend.</strong> - Data de Atendimento</li>
            </ul>
        </div>
    </div>
}