import React, { useEffect, useState } from 'react'
import Loading from '../../newcomponents/loading/loading'
import { Input } from '../../component'
import { getBRLCurrency } from '../../helpers/numbers'
import FormButtons from './form-buttons'

const maxValue = 100000000
const maxTypeCharacters = 200

export default function CostsForm({ cost, index, onSave, onClose }) {

    const [localCost, setLocalCost] = useState(cost)

    useEffect(() => {
        init()

    }, [cost])

    const init = () => {
        setLocalCost(cost ? cost : {type: '', cost: 0})
    }

    const getTitle = () => `${cost ? 'Editar' : 'Adicionar'} tipo de cobrança`

    const handleCost = ({ target }) => {
        const value =  target.value.replaceAll(/[RS$,.]/g, '')

        if (isNaN(value) || value > maxValue) return;

        setLocalCost({...localCost, cost: Number(value)})
    }

    const handleClose = () => {
        init()
        onClose()
    }

    const handleSave = async() => {
        await onSave({...localCost}, index)
        init()
    }

    const handleType = (e) => {
        if (e.target.value.length > maxTypeCharacters) return

        setLocalCost({...localCost, type: e.target.value})
    }

    if (!localCost) return <Loading></Loading> 

    return <div className='costs-form'>

        <div className='subtitle'>{getTitle()}</div>

        <form onSubmit={e => e.preventDefault()}>
            <Input
                label='Tipo'
                value={localCost.type}
                action={handleType}
            ></Input>
            <Input
                label='Valor'
                value={getBRLCurrency(localCost.cost)}
                action={handleCost}
            ></Input>

            <FormButtons
                onClose={handleClose}
                onSave={handleSave}
                saveText={cost ? 'Editar' : 'Adicionar'}
                disabled={localCost.type === ''}
            ></FormButtons>
        </form>
    </div>

}