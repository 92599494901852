import React from "react";

import "./tooltip.css"

export default function ToolTip ({message, children}) {

    return <div className="tooltip-wrapper">
        <div className="tooltip">
            {message}
        </div>
        <div className="tooltip-arrow"></div>
        {children}
    </div>
}