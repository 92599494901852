import './Funduscopy.css'
import React from 'react'
import { Input, RadioButton } from '../../../../../../../../../../component'

const IS_NORMAL_FIELD_OPTIONS = [
    {id: 'IS_NORMAL', name: 'Normal' }, 
    {id: 'ARE_OTHERS', name: 'Outros' }
]

export default function Funduscopy({
    handleChange, 
    holdingOphthalmology, 
    holdingOphthalmologyErr,
    handleChangeRadioButton,
    isEditable=true
}) {
    return <div className='inputBox-funduscopy'>
        <p><b>Fundoscopia</b></p>
        <span>Escavação</span>
        <div className='Funduscopy-Excavation'>
            <div>
                <Input
                    name='right_eye_funduscopy_excavation'
                    label='OD'
                    action={event => handleChange(event)}
                    actionBlur={event => handleChange(event)}
                    value={holdingOphthalmology?.right_eye_funduscopy_excavation}
                    errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                    disabled={!isEditable}
                />
            </div>
            <div>
                <Input
                    name='left_eye_funduscopy_excavation'
                    label='OE'
                    action={event => handleChange(event)}
                    actionBlur={event => handleChange(event)}
                    value={holdingOphthalmology?.left_eye_funduscopy_excavation}
                    errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                    disabled={!isEditable}
                />
            </div>
        </div>
        <RadioButton 
            name='funduscopy_is_normal' 
            action={handleChangeRadioButton}
            checked={holdingOphthalmology?.funduscopy_is_normal}
            options={IS_NORMAL_FIELD_OPTIONS}

        />
        {
            holdingOphthalmology?.funduscopy_is_normal !== 'IS_NORMAL' ?
            <div>
                <div className='Funduscopy-Header'>
                    <span></span>
                    <span>OD</span>
                    <span>OE</span>
                </div>
                <div className='Funduscopy-Grid'>
                    <span>Região Macular</span>
                    <Input
                        name='right_eye_funduscopy_macular_region'
                        action={event => handleChange(event)}
                        actionBlur={event => handleChange(event)}
                        value={holdingOphthalmology?.right_eye_funduscopy_macular_region}
                        errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                        disabled={!isEditable}
                    />
                    <Input
                        name='left_eye_funduscopy_macular_region'
                        action={event => handleChange(event)}
                        actionBlur={event => handleChange(event)}
                        value={holdingOphthalmology?.left_eye_funduscopy_macular_region}
                        errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                        disabled={!isEditable}
                    />
                    <span>Periferia</span>
                    <Input
                        name='right_eye_funduscopy_periphery'
                        action={event => handleChange(event)}
                        actionBlur={event => handleChange(event)}
                        value={holdingOphthalmology?.right_eye_funduscopy_periphery}
                        errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                        disabled={!isEditable}
                    />
                    <Input
                        name='left_eye_funduscopy_periphery'
                        action={event => handleChange(event)}
                        actionBlur={event => handleChange(event)}
                        value={holdingOphthalmology?.left_eye_funduscopy_periphery}
                        errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                        disabled={!isEditable}
                    />
                    <span>Vítreo</span>
                    <Input
                        name='right_eye_funduscopy_vitreous'
                        action={event => handleChange(event)}
                        actionBlur={event => handleChange(event)}
                        value={holdingOphthalmology?.right_eye_funduscopy_vitreous}
                        errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                        disabled={!isEditable}
                    />
                    <Input
                        name='left_eye_funduscopy_vitreous'
                        action={event => handleChange(event)}
                        actionBlur={event => handleChange(event)}
                        value={holdingOphthalmology?.left_eye_funduscopy_vitreous}
                        errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                        disabled={!isEditable}
                    />
                </div>
            </div>
            : null
        }
    </div>
}

