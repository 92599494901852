import React, { useEffect, useState } from 'react'
import TimeKeeper from 'react-timekeeper'
import { validarCampo } from '../../validation/Validation'

export function InputTime({
  children, 
  action,
  actionBlur,
  value, 
  label,
  name = '',
  type = 'text',
  color = '',
  disabled = false,
  errors={},
  required = {},
  maxLength = '1000'
}) {

  const [showTimeKeeper, setShowTimeKeeper] = useState(false)

  useEffect(() => {
    window.addEventListener('click', e => {

      if(!document.getElementById(`time-box-${name}`)?.contains(e.target))
        setShowTimeKeeper(false)
    })

    // eslint-disable-next-line
  }, [])

  const require = Object.keys(required)
  const pattern = e => {
    if (typeof required?.pattern === 'object') {
      return JSON.stringify(required.pattern)
    } else if (typeof required?.pattern === 'string') {
      return required.pattern
    } else {
      return ''
    }
  }
  const typename = 'X'
  const typename2 = 'ne'

  const validar = e => {
    const v = (require.length) ? validarCampo(e) : {}
    if (actionBlur) actionBlur(e, v)
  }

  const MaskTime = (value) => {

    if(value === '') return ''

    value = value.replace(/\D/g, '').replace(/(\d{2})(\d)/, '$1:$2')

    if(value.length > 4){
      let [hour, minute] = value.split(':');
      minute = minute.substr(0, 2);

      if(Number(hour) > 23){
        hour = '00';
      }

      if(Number(minute) > 59){
        value = `${hour}:00`
      } else value = `${hour}:${minute}`

    }

    return value

  }

  const thereIsError = (require.length && (required.erro[name])) || errors.error?.[name]

  return (
    <div id={`time-box-${name}`} className={`form-box ${color} ${thereIsError ? 'erro' : ''} `}>
      <label className='label-input' htmlFor={`id-${name}`}>{require.length?<span>*</span>:''} {label}</label>
      <div>
        <input 
          id={`id-${name}`} 
          type={type} 
          name={name} 
          value={MaskTime(value)} 
          onChange={action}
          onFocus={() => setShowTimeKeeper(true)}
          onBlur={e => validar(e)}
          disabled={disabled}
          maxLength={maxLength}
          pattern={pattern()}
          placeholder='--:--'
          autoComplete='off'
        />

        <div id='time-keeper' className='time-keeper' style={{position: 'absolute'}}>
          {showTimeKeeper && <TimeKeeper
            time={MaskTime(value).length >= 5 && MaskTime(value)}
            hour24Mode={true}
            onChange={time => action({target:{
              name:name,
              value: (time.hour < 10 ? '0' : '') + time.formatted24
            }})}
          ></TimeKeeper>}
        </div>

        <div className='input-actions'>
          {children && children.length
            ? children.map(e =>  {
              return e?.type && (e.type?.name === 'ActionForm' || e.type?.name === typename || e.type?.name === typename2) ? e : null
            })
            : children && children.type && (children.type?.name === 'ActionForm' || children.type?.name === typename || children.type?.name === typename2)
            ? children
            : null}
        </div>
      </div>
      {children && children.length
        ? children.map(e => e?.type && (e.type?.name !== 'ActionForm' && e.type?.name !== typename && e.type?.name !== typename2) ? e : null)
        : children && children.type && (children.type?.name !== 'ActionForm' && children.type?.name !== typename && children.type?.name !== typename2)
        ? children
        : null}
      {
        required.erro?.[name] || errors.error?.[name]
        ? <span className='campo-obrigatorio'>{required.message || errors.message}</span>
        : null
      }
    </div>
  )
}
