import './AppointmentLogsManager.css'
import React, { useContext, useEffect, useState, useRef } from 'react'
import { List, MaskDataHora, Paginate } from '../../../../component/index.js'
import Loading from '../../../../newcomponents/loading/loading.js'
import Modal, { MODAL_INIT_PROPS } from '../../../../newcomponents/modal/modal.js'
import { getAppointmentsLogsHistory } from '../../http.js'
import AppointmentLogsManagerFilters from './subcomponents/AppointmentLogsManagerFilters/AppointmentLogsManagerFilters.js'
import { useApp } from '../../../../layout/App.js'
import { parseAppointmentsURLQuerysetParams } from '../../helpers.js'
import DetailedAppointmentLogModal from './subcomponents/DetailedAppointmentLogModal/DetailedAppointmentLogModal.js'

const AppointmentsLogsHistoryContext = React.createContext()

export function useAppointmentsHistoryAppointmentLogsManager() {
	return useContext(AppointmentsLogsHistoryContext)
}

export const INIT_APPOINTMENT_FILTERS = {
	"name_or_cpf": "",
	"doctor__id__in": [],
	"specialty__id__in": [],
	"health_insurance__id__in": [],
	"classification__in": [],
	"decision__in": [],
	"priority__in": [],
	"result__in": [],
	"person__birthday__gte": "",
	"person__birthday__lte": "",
	"person__address__city__in": [],
	"service_location__id__in": [],
	"start__gte": '',
	"start__lte": new Date(),
	"related_diseases_code": [],
	"ordering": "-start",
}

export default function AppointmentLogsManager() {
	const [loading, setLoading] = useState(true)
	const [appointmentsLogsHistory, setAppointmentsLogsHistory] = useState([])
	const [pagination, setPagination] = useState({ pageNumber: 0, totalPages: 1, totalElements: 0 })
	const [modalProps, setModalProps] = useState(MODAL_INIT_PROPS)
	const [filters, setFilters] = useState(INIT_APPOINTMENT_FILTERS)
	const { currentHealthPlaceUser } = useApp()

	const { DECISION_CHOICES, CLASSIFICATION_CHOICES, RESULT_CHOICES, PRIORITY_CHOICES } = require('../../../../component/form/select/options.json')

	useEffect(() => {
		fetchInitAppointmentLogsManager()
	}, [])

	const fetchInitAppointmentLogsManager = async () => {
		setLoading(true)
		await fetchAppointmentsLogsHistory({ offset: 0 })
		setLoading(false)
	}

	const fetchAppointmentsLogsHistory = async params => {
		const builtParams = parseAppointmentsURLQuerysetParams(filters)
		params = { 
			offset: 0,
			...builtParams, 
			...params, 
			limit: 18, 
			health_place__id: currentHealthPlaceUser?.health_place?.id
		}

		try {
			let response = await getAppointmentsLogsHistory(params)
			setAppointmentsLogsHistory(response.data.results)
			setPagination({
				totalElements: response.data.count,
				totalPages: Math.ceil(response.data.count / 18),
				pageNumber: params.offset / 18,
			})
		} catch (err) {
			console.error('fetchAppointmentsLogsHistory ~ ', err)
		}
	}

	const handleNextPage = e => {
		fetchAppointmentsLogsHistory({ offset: e * 18 })
	}

	const headDefault = [
        { "colunm": "h_protocol", "text": "Atendimento" },
		{ "colunm": "h_patient", "text": "Paciente" },
		{ "colunm": "h_decision", "text": "Status" },
        { "colunm": "h_history_date", "text": "Horário de Alteração", },
		{ "colunm": "h_history_user", "text": "Dono da ação" },
    ]

	return (
		<AppointmentsLogsHistoryContext.Provider
			value={{
				appointmentsLogsHistory,
				pagination,
			}}>
			<Modal {...modalProps} dismissFn={() => setModalProps(MODAL_INIT_PROPS)} />
			<div className='AppointmentLogsManager-Content'>
				<AppointmentLogsManagerFilters
					filters={filters}
					setFilters={setFilters}
					fetchAppointmentsLogsHistory={fetchAppointmentsLogsHistory}
					setModalProps={setModalProps}
				/>
				<div className='AppointmentLogsManager-Separator' />
				<div className='AppointmentLogsManager-Body'>
					{
						loading 
						? <div className='AppointmentLogsManager-Loading'><Loading /></div>
						: <div>
							{
								appointmentsLogsHistory?.length
								? <div className='AppointmentLogsManager-AttendanceUnitWrapper'>
									<List 
										head={headDefault}
										data={appointmentsLogsHistory}
										listCustom={cursor => {
											console.log(cursor)
											const custom = cursor;
											const attendanceDecision = DECISION_CHOICES?.find(({ id }) => id === cursor?.decision)

											custom['h_protocol'] = <span 
												onClick={() => setModalProps(prev => ({
													...prev,
													open: true,
													title: 'Alterações realizadas',
													content: <DetailedAppointmentLogModal
														attendance={cursor} 
														setModalProps={setModalProps}
														fetchAttendancesHistory={fetchAppointmentsLogsHistory} 
													/>,
													dismissText: '',
												}))}
												className='AppointmentLogsManager-DetailLog-Span'
											>
												{cursor?.protocol || '-'}
											</span> 
											custom['h_patient'] = cursor?.person?.name || '-'
											custom['h_decision'] = attendanceDecision?.name
											let tempHistoryDate = MaskDataHora(cursor?.history_date)
											custom['h_history_date'] = tempHistoryDate?.data && tempHistoryDate?.hora
												? <span>{tempHistoryDate?.data} <br /> {tempHistoryDate?.hora}</span> 
												: '-'
											custom['h_history_user'] = cursor?.history_user?.email && cursor?.history_user?.name
												? <span>{cursor?.history_user?.name} <br /> {cursor?.history_user?.email}</span>
												: 'Sistema Salutho'
											return custom
										}}
									/>
									<Paginate data={pagination} action={handleNextPage} />
								</div>
								: <div className='AppointmentLogsManager-NotFound'>
									<div>
										<p><b>Nenhum log foi encontrado</b></p>
									</div>
								</div>
							}
						</div> 
					}
				</div>
			</div>
		</AppointmentsLogsHistoryContext.Provider>
	)
}
