import './DeleteChatModal.css';
import React from "react";
import { useDispatch } from "react-redux";
import NewButton, { ButtonModel } from '../../../../../../newcomponents/button/button';
import { MODAL_INIT_PROPS } from '../../../../../../newcomponents/modal/modal';
import { AddAlert, AlertType } from '../../../../../../component';

import { deleteChatRoom } from '../../../../http';

export default function DeleteChatModal({ chat, setModalInfo, onChatsUpdate }) {
    const dispatch = useDispatch();

    const handleCancel = () => {
        dispatch(setModalInfo(MODAL_INIT_PROPS)); // Fecha o modal
    };

    const handleDeleteChat = async () => {
        try {
            await deleteChatRoom({ room_id: chat.id })
            dispatch(AddAlert('Chat', 'Chat excluído com sucesso!', AlertType.SUCCESS));
            onChatsUpdate()
        } catch (error) {
            dispatch(AddAlert('Chat', 'Falha ao excluir o chat.', AlertType.ERROR));
        } finally {
            dispatch(setModalInfo(MODAL_INIT_PROPS)); // Fecha o modal após a ação
        }
    }

    return (
        <div className='DeleteChatModal'>
            <p>Tem certeza que deseja excluir {chat.name? <b>{chat.name}</b> : 'esta conversa'}? Esta ação não pode ser desfeita.</p>
            <div className='DeleteChatModal-BtnBox'>
                <NewButton 
                    onClick={handleCancel}
                    label='Cancelar'
                    model={ButtonModel.SECONDARY}
                />
                <NewButton 
                    onClick={handleDeleteChat}
                    label='Confirmar'
                    model={ButtonModel.DANGER}
                />
            </div>
        </div>
    );
}
