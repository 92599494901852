import './Hospitalizations.css';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ChartIcon from '../../../../../../../../component/icon/chart';
import ListIcon from '../../../../../../../../component/icon/list';
import NewTaskIcon from '../../../../../../../../component/icon/new_task';
import Modal, { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal';
import { AddAlert, AlertType } from '../../../../../../../../component';
import ShowHospitalizations from './subcomponents/ShowHospitalizations/ShowHospitalizations';
import TableHospitalizations from './subcomponents/TableHospitalizations/TableHospitalizations';
import { getHospitalization } from '../../../../../../http';
import NewEditHospitalizations from './subcomponents/NewEditHospitalizations/NewEditHospitalizations';
import DeleteHospitalizations from './subcomponents/DeleteHospitalizations/DeleteHospitalizations';


export default function Hospitalizations({ person, attendanceID=null, }) {
  const dispatch = useDispatch()
  const [hospitalizationsHistory, setHospitalizationsHistory] = useState([])
  const [openSection, setOpenSection] = useState('TableHospitalizations')
  const [modalInfo, setModalInfo] = useState(MODAL_INIT_PROPS)
  const [pagination, setPagination] = useState({ pageNumber: 0, totalPages: 1, totalElements: 0 })

  useEffect(() => {
    fetchHospitalizations({ offset: 0 })
  }, [])

  const fetchHospitalizations = async (params={}) => {
    params = { ...params, "active": true, "person__id": person?.id }
    
    try {
      let res = await getHospitalization(params)
      setHospitalizationsHistory(res.data.results)
      setPagination({
        totalElements: res.data.count,
        totalPages: Math.ceil(res.data.count / 10),
        pageNumber: params.offset / 10,
      })
    } catch (err) {
      dispatch(AddAlert('Hospitalização', 'Falha ao obter  hospitalizações', AlertType.ERROR))
      console.error('Hospitalização ~ fetchHospitalizations ~ ', err)
    }
  }

  const extraAction = async () => {
    await fetchHospitalizations({ offset: 0, active: true })
    setModalInfo(MODAL_INIT_PROPS)
  }
  
  const openNewEditHospitalizationsModal = (cursor) => {
    setModalInfo(prev => ({
      ...prev,
      open: true,
      title: 'Hospitalização',
      content: <NewEditHospitalizations
        initHospitalization={cursor}
        person={person}
        attendanceID={attendanceID}
        extraAction={extraAction} 
      />
    }))
  }
  
  const openDeleteHospitalizationsModal = (cursor) => {
    setModalInfo(prev => ({
      ...prev,
      open: true,
      title: 'Deleção de Hospitalização',
      content: <DeleteHospitalizations
        hospitalization={cursor} 
        extraAction={extraAction}
      />
    }))
  }

  const sections = {
    'ShowHospitalizations': <ShowHospitalizations 
      hospitalizationsHistory={hospitalizationsHistory}
      fetchHospitalizations={fetchHospitalizations}
      setModalInfo={setModalInfo} 
    />,
    'TableHospitalizations': <TableHospitalizations 
      hospitalizationsHistory={hospitalizationsHistory}
      fetchHospitalizations={fetchHospitalizations} 
      openNewEditHospitalizationsModal={openNewEditHospitalizationsModal}
      openDeleteHospitalizationsModal={openDeleteHospitalizationsModal}
      pagination={pagination}
    />
  }

  return (
    <div>
      <Modal {...modalInfo} dismissFn={() => setModalInfo(MODAL_INIT_PROPS)} />
      <div className='Hospitalizations-ActionRow'>
        <div
          onClick={() => openNewEditHospitalizationsModal()}
          title='Nova hospitalização'
        >
          <NewTaskIcon />
        </div>
        {/*openSection === 'ShowHospitalizations' ? (
          <div onClick={() => setOpenSection('TableHospitalizations')} title='Ver Dados'>
            <ListIcon />
          </div>
        ) : (
          <div onClick={() => setOpenSection('ShowHospitalizations')} title='Ver Gráficos'>
            <ChartIcon />
          </div>
        )*/}
      </div>
      <div>
        { sections['TableHospitalizations'] }
      </div>
    </div>
  );
}
