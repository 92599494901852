import './NewEditHospitalizations.css'
import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import ReactQuill from 'react-quill';
import { AddAlert, AlertType, Input, adicionaZero } from '../../../../../../../../../../component';
import { formatISO8601Str } from '../../../../../../../../../../utils/convertDate';
import CalendarDateModal from '../../../../../../../../../../component/calendar/CalendarDateModal';
import Modal, { MODAL_INIT_PROPS } from '../../../../../../../../../../newcomponents/modal/modal';
import { strpHospitalization } from '../../utils';
import NewButton, { ButtonModel } from '../../../../../../../../../../newcomponents/button/button';
import { putHospitalization } from '../../../../../../../../http';
import { loaded, loading } from '../../../../../../../../../../layout/redux/AppActions';


export default function NewEditHospitalizations({ person, attendanceID=null, initHospitalization=null, extraAction=async () => null }) {
    const dispatch = useDispatch()
    const [holdingHospitalization, setHoldingHospitalization] = useState(strpHospitalization(initHospitalization))
    const [secondaryModal, setSecondaryModal] = useState(MODAL_INIT_PROPS)

    const openCalendarCervicalExamDateModal = () => {
		openCalendarDateModal('hospitalization_date', 'Data de hospitalização');
	};

    const handleSelectDateObj = (name, {yearSearchCursor, monthSearchCursor, daySearchCursor}) => {
		let nextDate = new Date(yearSearchCursor, parseInt(monthSearchCursor, 10) - 1, daySearchCursor)
		setHoldingHospitalization(prev => ({ ...prev, [name]: nextDate }))
	}

    const openCalendarDateModal = (filterKey, modalTitle) => {
		const date_cursor = holdingHospitalization?.[filterKey];
		const currentDate = date_cursor ? new Date(date_cursor) : new Date();
	
		const currentDay = String(adicionaZero(currentDate.getDate()));
		const currentMonth = String(adicionaZero(currentDate.getMonth() + 1)); 
		const currentYear = String(currentDate.getFullYear());
		
		setSecondaryModal(prev => ({
			...prev,
			open: true,
			title: modalTitle,
			content: (
				<CalendarDateModal
					name={filterKey}
					currentDay={currentDay}
					currentMonth={currentMonth}
					currentYear={currentYear}
					renderNextSelectedDay={(dateEvent) => {
						handleSelectDateObj(filterKey, dateEvent);
						setSecondaryModal(MODAL_INIT_PROPS);
					}}
				/>
			)
		}));
	};

    const handleChangeInteger = (event) => {
        let nextValue = event.target.value < 0
            ? 0 
            : event.target.value > 9999 
            ? 9999 
            : Number(event.target.value)
        setHoldingHospitalization(prev => ({ ...prev, [event.target.name]: nextValue}))
    }

    const handleChangeText = (text) => {
        setHoldingHospitalization(prev => ({ ...prev, 'reason_for_hospitalization': text }))
    }

    const saveHospitalization = async () => {
        const payload = { ...holdingHospitalization, "attendance": attendanceID, "person": person }
        
        if (!payload?.number_of_days_in_hospital && !payload?.reason_for_hospitalization) {
			dispatch(AddAlert('Salvar Hospitalização', 'Preencha pelo menos um dos campos. ', AlertType.ERROR))
			return
		}
        
        dispatch(loading())
        try {
            await putHospitalization(payload)
            dispatch(AddAlert('Hospitalização', 'Hospitalização salva com sucesso!', AlertType.SUCCESS))
            extraAction(MODAL_INIT_PROPS)
        } catch (err) {
            console.error('saveHospitalization', err)
        }
        dispatch(loaded())
    }

    return <div className='NewEditHospitalizations'>
        <Modal {...secondaryModal} dismissFn={() => setSecondaryModal(MODAL_INIT_PROPS)} />

        <div className='NewEditHospitalizations-Body'>
            <div>
                <div className='NewEditHospitalizations-Grid'>
                    <div className='NewEditHospitalizations-Input'>
                        <b>Data de Hospitalização</b>
                        <div>
                            <Input
                                name='hospitalization_date'
                                placeholder='DD/MM/YYYY'
                                actionFocus={openCalendarCervicalExamDateModal}
                                value={formatISO8601Str(holdingHospitalization.hospitalization_date)}
                                action={(event) => console.log(event)}
                            />
                        </div>
                    </div>
                    <div className='NewEditHospitalizations-Input'>
                        <span><b>Número de dias no hospital</b></span>
                        <div >
                            <Input
                                name='number_of_days_in_hospital'
                                value={holdingHospitalization.number_of_days_in_hospital}
                                action={handleChangeInteger}
                                type='number'
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <p><b>Motivo de hospitalização</b></p>
                <ReactQuill
                    id='reason_for_hospitalization'
                    theme="snow"
                    value={holdingHospitalization.reason_for_hospitalization}
                    onChange={handleChangeText}
                    //onBlur={(previousRange, source, editor) => handleBlurAppointmentTextField(field_type, editor.getHTML())}
                />
            </div>
        </div>
        <div className='NewEditHospitalizations-BtnBox'>
            <NewButton
                label='Salvar hospitalização'
                onClick={saveHospitalization}
            />
        </div>
    </div>
}