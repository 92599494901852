import './AppointmentInstance.css'
import React from "react";
import { useHistory } from 'react-router-dom'
import { MaskDataHora } from '../../../../../../../../component';
import NewAppointmentModal from '../../../../../AttendanceHistory/subcomponents/NewAppointmentModal/NewAppointmentModal';
import { useApp } from '../../../../../../../../layout/App';

const { DECISION_CHOICES, CLASSIFICATION_CHOICES, RESULT_CHOICES, PRIORITY_CHOICES } = require('../../../../../../../../component/form/select/options.json')

// Definição dos campos a serem comparados
const fieldsToCompare = [
    { field: 'doctor', displayName: 'Profissional', getValue: (obj) => obj?.name || 'Não selecionado' },
    { field: 'classification', displayName: 'Categoria', getValue: (obj) => CLASSIFICATION_CHOICES?.find(({ id }) => id === obj)?.name || 'Não selecionado' },
    { field: 'decision', displayName: 'Status', getValue: (obj) => DECISION_CHOICES?.find(({ id }) => id === obj)?.name || 'Não selecionado' },
    { field: 'result', displayName: 'Tipo', getValue: (obj) => RESULT_CHOICES?.find(({ id }) => id === obj)?.name || 'Não selecionado' },
    { field: 'priority', displayName: 'Prioridade', getValue: (obj) => PRIORITY_CHOICES?.find(({ id }) => id === obj)?.name || 'Não selecionado' },
    { field: 'health_insurance', displayName: 'Convênio', getValue: (obj) => obj?.name || 'Não selecionado' },
    { field: 'specialty', displayName: 'Especialidade', getValue: (obj) => obj?.name || 'Não selecionada' },
    { field: 'service_location', displayName: 'Local de Atendimento', getValue: (obj) => obj?.name || 'Não selecionado' },
    // Adicione mais campos conforme necessário
];

export default function AppointmentInstance({ attendance = null, setModalProps = () => null, fetchAttendancesHistory = async () => null }) {
    const history = useHistory()
    const { isHealthProfessional } = useApp()
    const attendanceDate = MaskDataHora(attendance?.start)

    const openConsultation = (id) => {
        history.push(`/consultation/${id}`)
    }

    // Função para identificar mudanças
    const identifyChanges = (current, previous) => {
        const changes = [];

        fieldsToCompare.forEach(({ field, displayName, getValue }) => {
            const currentValue = getValue(current[field]);
            const previousValue = getValue(previous[field]);

            if (currentValue !== previousValue) {
                changes.push({
                    displayName,
                    before: previousValue,
                    after: currentValue,
                });
            }
        });

        return changes;
    };

    const changes = attendance?.previous_record ? identifyChanges(attendance, attendance.previous_record) : [];
    const hasChanges = changes.length > 0;

    return (
        <div className='AppointmentInstance'>
            <div className='AppointmentInstance-Header'>
                <div>
                    <div className='AppointmentInstance-Header-TitleBox'>
                        <b className='AppointmentInstance-Header-TitleBox-PatientName'>
                            {attendance.person?.social_name || attendance.person?.name || 'Paciente não encontrado'}
                        </b>
                    </div>
                    <span className='AppointmentInstance-Header-AltText'>
                        {attendance?.protocol || '-'} em {attendanceDate?.data && attendanceDate?.hora ? `${attendanceDate?.data} ${attendanceDate?.hora}` : '-'}
                        {attendance?.person?.social_name ? (` | Nome de registro: ${attendance.person.name}` || 'Nome não registrado') : ''}
                    </span>
                    
                    {hasChanges ? (
                        <div className='AppointmentInstance-Header-ChangesBox'>
                            <span className='AppointmentInstance-Header-ChangesBox-Text'>Alterações:</span>
                            <div className='AppointmentInstance-Header-TitleBox-AlertsBox'>
                                {changes.map(({ displayName, before, after }) => (
                                    <div key={displayName} className='AppointmentInstance-AppointmentBox-Subtitle-DecisionBox'>
                                        <b>{`${displayName}: ${before} -> ${after}`}</b>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )
                    : <div className='AppointmentInstance-Header-ChangesBox'>
                        <span className='AppointmentInstance-Header-ChangesBox-Text'>Novo agendamento criado</span>
                    </div>
                }
                </div>
            </div>
            <div className='AppointmentInstance-ActionRow'>
                {
                    isHealthProfessional
                    ? <div 
                        className='AppointmentInstance-ActionRow-Btn' 
                        onClick={() => openConsultation(attendance?.id)}
                    >
                        <b>Ver atendimento</b>
                    </div>
                    : null
                }
                <div
                    className='AppointmentInstance-ActionRow-Btn' 
                    onClick={() => setModalProps({
                        open: true,
                        title: `Editar atendimento | ${MaskDataHora(attendance.start)?.data} | ${MaskDataHora(attendance.start)?.hora}`,
                        content: <NewAppointmentModal
                            initAppointment={attendance}
                            fetchAppointments={fetchAttendancesHistory}
                            setModalInfo={setModalProps}
                        />
                    })}
                >
                    <b>Editar</b>
                </div>
            </div>
        </div>
    )
}


