import React, { useEffect, useRef, useState } from 'react';
import './BookingAppointmentSelect.css'
import { Input } from "../../../../../../component";
import NewSelect from "../../../../../../newcomponents/form/select/select";
import { stringifyCollaboratorOption } from "../../../../../../utils/stringifyCollaboratorOption";
import { getCollaborators } from "../../../../../collaborators/http";
import { useDispatch } from 'react-redux';
import { loaded, loading } from '../../../../../../layout/redux/AppActions';

const BookingAppointmentSelect = ({ currentHealthPlaceUser, isHealthProfessional, onSelect }) => {
    const dispatch = useDispatch();
    const searchTimeOut = useRef();
    const [healthProfessionals, setHealthProfessionals] = useState([]);
    const [selectedHealthProfessional, setSelectedHealthProfessional] = useState(null);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        if (!isFetching) {
            fetchFirstTimeHealthProfessionals();
        }
        if (isHealthProfessional && currentHealthPlaceUser) {
            const preSelected = stringifyCollaboratorOption(currentHealthPlaceUser);
            setSelectedHealthProfessional(preSelected);
            onSelect(preSelected.id);
        }

    }, []);

    const fetchFirstTimeHealthProfessionals = async () => {
        dispatch(loading());
        setIsFetching(true);
        try {
            await fetchHealthProfessionals({});
        } finally {
            setIsFetching(false);
            dispatch(loaded());
        }
    };

    const fetchHealthProfessionals = async (params = {}) => {
        try {
            const res = await getCollaborators({
                ...params,
                health_place__id: currentHealthPlaceUser?.health_place?.id,
                limit: 500,
                profile__in: 'DOCTOR%2CDENTIST%2CPSYCHOLOGIST%2CNUTRITIONIST%2CNURSE',
                has_person: true,
            });

            const results = res.data.results.map(instance => stringifyCollaboratorOption(instance));
            setHealthProfessionals(results);
        } catch (err) {
            console.error('Erro ao buscar profissionais:', err);
            setHealthProfessionals([]);
        }
    };

    const handleSearch = (event) => {
        if (searchTimeOut.current) clearTimeout(searchTimeOut.current);
        searchTimeOut.current = setTimeout(() => {
            fetchHealthProfessionals({ 'name_cpf_or_email': event.target.value });
        }, 400);
    };

    const handleBlur = () => {
        fetchHealthProfessionals({ 'name_cpf_or_email': '' });
    };

    const handleSelect = (event) => {
        const selectedId = event.target.selected;
        const selected = healthProfessionals.find(({ id }) => id === Number(selectedId));
        setSelectedHealthProfessional(selected);
        onSelect(selectedId);
    };

    return (
        <div className='BookingAppointment-Select'>
            <b>Profissional selecionado</b>
            <NewSelect
                options={healthProfessionals}
                selected={selectedHealthProfessional?.id || ''}
                optionStrKey='str'
                onSelect={handleSelect}
                filterNode={
                    <div className='BookingAppointment-NewSelect-FilterBar'>
                        <Input
                            placeholder='Pesquisar por e-mail, nome ou CPF'
                            action={handleSearch}
                            actionFocus={handleBlur}
                        />
                    </div>
                }
            />
        </div>
    );
};

export default BookingAppointmentSelect;
