import './VitalSigns.css';
import React, { useEffect, useState } from 'react';
import ChartIcon from '../../../../../../../../component/icon/chart';
import ListIcon from '../../../../../../../../component/icon/list';
import TableVitalSigns from './subcomponents/TableVitalSigns/TableVitalSigns';
import ChartVitalSigns from './subcomponents/ChartVitalSigns/ChartVitalSigns';
import NewTaskIcon from '../../../../../../../../component/icon/new_task';
import Modal, { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal';
import NewEditVitalSign from './subcomponents/NewEditVitalSign/NewEditVitalSign';
import { useDispatch } from 'react-redux';
import { AddAlert, AlertType } from '../../../../../../../../component';
import { getVitalSigns } from '../../../../../../http';
import DeleteVitalSign from './subcomponents/DeleteVitalSign/DeleteVitalSign';


export default function VitalSigns({ person, attendanceID=null,  }) {
  const dispatch = useDispatch()
  const [vitalSigns, setVitalSigns] = useState([])
  const [openSection, setOpenSection] = useState('TableVitalSigns')
  const [modalInfo, setModalInfo] = useState(MODAL_INIT_PROPS)
  const [pagination, setPagination] = useState({ pageNumber: 0, totalPages: 1, totalElements: 0 })

  const fetchVitalSigns = async (params={}) => {
    params = { ...params, "active": true, "person__id": person?.id }
    
    try {
      let res = await getVitalSigns(params)
      setVitalSigns(res.data.results)
      setPagination({
          totalElements: res.data.count,
          totalPages: Math.ceil(res.data.count / 10),
          pageNumber: params.offset / 10,
      })
    } catch (err) {
      dispatch(AddAlert('Sinais Vitais', 'Falha ao obter sinais vitais', AlertType.ERROR))
      console.error('VitalSigns ~ fetchVitalSigns ~ ', err)
    }
  }

  const extraAction = async () => {
    await fetchVitalSigns({ offset: 0, active: true })
    setModalInfo(MODAL_INIT_PROPS)
  }

  const openNewEditVitalSignModal = (cursor) => {
    setModalInfo(prev => ({
      ...prev,
      open: true,
      title: 'Sinais Vitais',
      content: <NewEditVitalSign
        initVitalSign={cursor}
        person={person}
        attendanceID={attendanceID}
        extraAction={extraAction} 
      />
    }))
  }

  const openDeleteVitalSignModal = (cursor) => {
    setModalInfo(prev => ({
      ...prev,
      open: true,
      title: 'Deleção de Sinais Vitais',
      content: <DeleteVitalSign 
        vitalSign={cursor} 
        extraAction={extraAction}
      />
    }))
  }

  const sections = {
    'ChartVitalSigns': <ChartVitalSigns
      vitalSigns={vitalSigns}
      fetchVitalSigns={fetchVitalSigns}
      setModalInfo={setModalInfo} 
    />,
    'TableVitalSigns': <TableVitalSigns 
      vitalSigns={vitalSigns}
      fetchVitalSigns={fetchVitalSigns}
      openNewEditVitalSignModal={openNewEditVitalSignModal}
      openDeleteVitalSignModal={openDeleteVitalSignModal}
      pagination={pagination}
    />
  }

  return (
    <div>
      <Modal {...modalInfo} dismissFn={() => setModalInfo(MODAL_INIT_PROPS)} />
      <div className='VitalSigns-ActionRow'>
        <div
          onClick={() => openNewEditVitalSignModal()}
          title='Novo Sinal Vital'
        >
          <NewTaskIcon />
        </div>
        {openSection === 'ChartVitalSigns' ? (
          <div onClick={() => setOpenSection('TableVitalSigns')} title='Ver Dados'>
            <ListIcon />
          </div>
        ) : (
          <div onClick={() => setOpenSection('ChartVitalSigns')} title='Ver Gráficos'>
            <ChartIcon />
          </div>
        )}
      </div>
      <div>
        { sections[openSection] }
      </div>
    </div>
  );
}
