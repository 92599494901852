import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Input, IcoEye, IcoEyeBlocked } from '../../component'
import './login.css'
import { history } from '../../helpers'
import { useLocation } from "react-router-dom";
import ActionForm from '../../component/form/actions/actions'
import Button, { ButtonModel } from '../../newcomponents/button/button'
import { logIn } from '../../layout/redux/AppActions'

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const LoginForm = ({ setDentistAppointmentPage=null }) => {
  const dispatch = useDispatch()
  const [inputState, setInputState] = useState({ email: '', senha: '' })
  const [inputStateErr, setInputStateErr] = useState({ email: '', senha: '' })
  const [senha, setSenha] = useState(true)
  const [hasSubmitted, setHasSubmitted] = useState(false)

  const handlePressEnter = useCallback(event => {
    const { key } = event

    if (key === 'Enter') handleLogin()
  })

  useEffect(() => {
    window.addEventListener('keydown', handlePressEnter)

    return () => window.removeEventListener('keydown', handlePressEnter)

    // eslint-disable-next-line
  }, [handlePressEnter])

  useEffect(()=>{
    if (hasSubmitted) {
      validateFields()
    }
  },[inputState])

  const handleChange = e => {
    setInputState({ ...inputState, [e.target.name]: e.target.value })

    if (hasSubmitted) validateFields()
  }

  function handleLogin() {
    setHasSubmitted(true)
    const hasErrors = validateFields()
  
    if (hasErrors) {
      return
    }
    
    setSenha(true)
    dispatch(logIn(inputState))
  }

  const validateFields = () => {
    let errors = {}
    if (!inputState.email) errors['email'] = "Campo obrigatório."
    if (!inputState.senha) errors['senha'] = "Campo obrigatório."

    if (inputState.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputState.email)) {
      errors['email'] = "Email inválido."
    }
    
    setInputStateErr(errors)
    if (Object.keys(errors).length) {
      return true
    }
  }
  
  return <>
    <Input 
      label='E-mail' 
      name='email' 
      action={handleChange} 
      value={inputState.email} 
      errors={{ error: inputStateErr, message: inputStateErr?.email }}
    />
    <Input
      label='Senha'
      type={senha ? 'password' : 'text'}
      name='senha'
      action={handleChange}
      value={inputState.senha}
      errors={{ error: inputStateErr, message: inputStateErr?.senha }}
    >
      <ActionForm action={() => setSenha(!senha)} title={senha ? 'visivel' : 'invisivel'}>
          {senha ? <IcoEye /> : <IcoEyeBlocked />}
      </ActionForm>
    </Input>
    <p className='LoginPage-ForgetMyPassword' onClick={() => history.push('/recuperar-senha')}>Esqueci minha senha</p>


    <div className='LoginPage-Btn LoginPage-Btn-Mt'>
      <Button 
        label='Entrar'
        onClick={handleLogin}
      />
    </div>
    {
      setDentistAppointmentPage
      ? <div className='LoginPage-Btn'>
        <Button 
          label='Voltar'
          onClick={() => setDentistAppointmentPage('1')}
          model={ButtonModel.SECONDARY}
        />
      </div>
      : null
    }
  </>
}
export default function LoginPage() {
  const dispatch = useDispatch()
  const query = useQuery();
  const appStore = require('../../media/AppStore.png')
  const googlePlay = require('../../media/GooglePlay.png')
  
  useEffect(() => {
    localStorage.clear()
    if (query.get('message') && query.get('tag')) {
      history.push('/emailValido')
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className='LoginPage'>
      <section>
        <h3>{process.env.REACT_APP_SITE_TITLE}</h3>
        <LoginForm />
      </section>
      {
        false
        ? <div className='LoginPage-Register'>
          <span>Não tem uma conta? <b onClick={() => history.push('/registrar')}>Registrar</b></span>
        </div>
        : null
      }

    </div>
  )
}
  