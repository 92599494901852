import './ChatListItem.css'
import { useEffect, useState } from 'react'
import { useApp } from '../../../../../../layout/App'
import EditChatModal from '../EditChatModal/EditChatModal'
import { deleteChatRoom } from '../../../../http'
import { AddAlert } from '../../../../../../component'
import PersonIco from '../../../../../../component/icon/personIcon'
import GroupIco from '../../../../../../component/icon/group'
import { IcoBin } from '../../../../../../component'
import { IcoMoreOptions } from '../../../../../../component'
import { IcoPencil } from '../../../../../../component'
import { CleanBroomIco } from '../../../../../../component'
import { ExitIco } from '../../../../../../component'
import { dispatch, style } from 'd3'
import { AlertType } from '../../../../../../component'
import DeleteChatModal from '../DeleteChatModal/DeleteChatModal'
import { updateChatRoomMembers } from '../../../../http'

// const defaultUser = require('../../../../../../media/DefaultUser.png')

export default function ChatListItem({ chat, lastReadMessage, onlineUsers, onChatsUpdate, setModalInfo }) {
    const { currentHealthPlaceUser } = useApp()
    const [isMoreOptions, setIsMoreOptions] = useState(false)

    const getParticipantOtherThanMe = () => {
        const otherParticipant = chat.participants?.find(participant => participant.id !== currentHealthPlaceUser.user.id)
        return otherParticipant
    }
    const otherParticipant = getParticipantOtherThanMe()

    const getOtherParticipantStatus = () => {
        if (!chat?.participants || !currentHealthPlaceUser?.user) {
            return false
        }
        const otherParticipant = chat.participants.find(participant => participant.id !== currentHealthPlaceUser.user.id);
        const isOnline = onlineUsers.onlineUsers[otherParticipant.id];

        return isOnline || false;
    }

    const handleMouseLeave = () => {
        setIsMoreOptions(false);
    };

    const handleOpenCloseMoreOptions = (event) => {
        event.stopPropagation(); //avoids chat opening
        setIsMoreOptions(!isMoreOptions)
    }

    const handleOpenEditModal = () => {

        setModalInfo({
            open: true,
            title: 'Editar Conversa',
            content: (
                <EditChatModal
                    onChatsUpdate={onChatsUpdate}
                    chatInfo={chat}
                    setModalInfo={setModalInfo}
                />
            ),
        });
        setIsMoreOptions(false);
    };

    const handleOpenDeleteChatModal = () => {
        setModalInfo({
            open: true,
            title: 'Excluir Conversa',
            content: (
                <DeleteChatModal chat={chat} setModalInfo={setModalInfo} onChatsUpdate={onChatsUpdate} ></DeleteChatModal>
            ),
        });
    }
    
    // const leaveChatRoom = async () => {
    //     const room_id = chat.id
    //     const user_id = currentHealthPlaceUser?.user.id
    //     try {
    //         // Remove o usuário logado do grupo
    //         await updateChatRoomMembers({
    //             room_id,
    //             removeMembers: [user_id]
    //         });

    //         dispatch(AddAlert('Sair da conversa', 'Você saiu da conversa em grupo.', AlertType.SUCCESS));

    //     } catch (error) {
    //         dispatch(AddAlert('Sair da conversa', 'Erro ao sair da conversa em grupo.', AlertType.ERROR));
    //     }
    // }

    return (
        <div className='ChatList-Item' onMouseLeave={handleMouseLeave}>
            <div className='ChatList-Item-LeftInfo'>
                <div className='ChatList-Item-IconDiv'>
                    {chat.is_group ? (
                        <GroupIco style={{ width: '25px', height: '25px', color: '#aaa' }} />
                    ) : otherParticipant?.avatar_img_url ? (
                        <img src={otherParticipant.avatar_img_url} alt='Avatar' />
                    ) : (
                        <PersonIco width={25} height={25} color={'#aaa'} />
                    )}
                    {!chat.is_group && (
                        <div
                            className={`ChatList-Item-Status ${getOtherParticipantStatus() ? 'online' : 'offline'}`}
                            title={getOtherParticipantStatus() ? 'Online' : 'Offline'}
                        ></div>
                    )}
                </div>
                <p className='ChatList-Item-Name'>{chat.is_group ? chat.name : otherParticipant?.name}</p>
            </div>
            {lastReadMessage?.unread_count > 0 && (
                <div className='ChatList-Item-UnreadMessages'>
                    <p className='ChatList-Item-UnreadMessages-Number' title='Mensagens não lidas'>
                        {lastReadMessage?.unread_count}
                    </p>
                </div>
            )}
            <div>
                <span className='ChatList-Item-MoreOptions-IconDiv' title='Opções' onClick={handleOpenCloseMoreOptions} >
                    <IcoMoreOptions></IcoMoreOptions>
                </span>
                {isMoreOptions && <div className={`ChatList-Item-MoreOptions ${chat.is_group ? 'group' : ''
                    }`} onClick={(event) => { event.stopPropagation(); }}>
                    {chat.is_group && <p onClick={handleOpenEditModal}>
                        <IcoPencil></IcoPencil>
                        Editar Conversa
                    </p>}
                    {/* {chat.is_group && <p onClick={leaveChatRoom}>
                        <ExitIco style={{width: '1.25rem'}}></ExitIco>
                        Sair da conversa
                    </p>} */}
                    {/* <p>
                        <CleanBroomIco style={{width: '1rem', height: '1rem'}} ></CleanBroomIco>
                        Limpar Conversa
                    </p> */}
                    <p onClick={handleOpenDeleteChatModal} style={{ color: 'var(--error-bg)' }}>
                        <IcoBin></IcoBin>
                        Excluir Conversa
                    </p>
                </div>}
            </div>
        </div>
    );
}