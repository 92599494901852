import React from 'react'

export function IcoReviewDocument({ style }) {
  return (
    <svg style={style} className='icon-reviewdocument' viewBox="0 0 512 512">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
            <path d="M1740 5010 c-108 -23 -220 -112 -274 -218 l-26 -50 -187 -4 c-213 -3
            -237 -10 -321 -91 -60 -59 -82 -123 -82 -242 l0 -95 -168 0 c-184 0 -250 -11
            -333 -57 -63 -34 -148 -119 -182 -181 -59 -109 -57 -21 -57 -1869 0 -1216 3
            -1707 11 -1745 26 -123 113 -235 227 -296 120 -64 59 -62 1468 -62 874 0 1299
            3 1340 11 166 30 304 154 354 318 17 54 20 93 20 264 l0 201 358 -355 c334
            -332 362 -357 427 -387 203 -91 416 -48 565 115 96 105 134 214 128 362 -7
            174 -1 165 -445 612 l-384 386 35 102 c50 142 68 249 68 405 1 358 -126 674
            -372 926 -92 94 -168 152 -288 220 l-92 53 0 298 c0 264 -2 304 -19 358 -36
            118 -118 214 -228 269 -84 42 -144 52 -322 52 l-158 0 -6 106 c-9 158 -47 226
            -161 287 -38 20 -58 22 -237 25 l-196 3 -23 52 c-34 76 -140 178 -212 205 -68
            25 -166 35 -228 22z m169 -229 c60 -31 99 -83 107 -142 4 -32 16 -62 31 -80
            l25 -29 222 0 c230 0 278 -7 296 -40 6 -11 10 -130 10 -283 0 -246 -1 -266
            -19 -288 l-19 -24 -725 -3 -725 -2 -29 24 -28 24 0 271 c0 268 0 271 23 293
            22 22 29 23 267 28 284 6 281 5 294 102 4 30 16 70 27 88 42 72 162 102 243
            61z m-1067 -783 c3 -91 8 -121 26 -154 29 -57 77 -103 137 -130 l50 -24 761 0
            761 0 61 31 c110 55 152 130 160 287 l5 104 166 -4 c182 -4 210 -11 275 -68
            20 -18 47 -52 59 -74 21 -39 22 -56 25 -300 3 -227 1 -258 -12 -253 -109 42
            -442 55 -599 24 -177 -36 -354 -112 -514 -223 l-93 -64 -631 0 -631 0 -29 -29
            c-38 -39 -40 -97 -5 -138 l24 -28 541 -5 541 -5 -41 -60 c-22 -33 -60 -99 -85
            -147 l-44 -88 -446 0 -446 0 -29 -29 c-41 -41 -41 -101 0 -142 l29 -29 421 0
            c232 0 421 -1 421 -3 0 -1 -7 -34 -15 -72 -8 -39 -15 -105 -15 -147 l0 -77
            -411 -3 -411 -3 -24 -28 c-35 -41 -33 -99 5 -138 l29 -29 406 0 c392 0 406 -1
            406 -19 0 -20 53 -233 65 -263 7 -17 -17 -18 -426 -18 -413 0 -436 -1 -466
            -20 -23 -13 -34 -29 -39 -54 -12 -68 17 -118 78 -130 17 -3 240 -6 497 -6
            l466 0 40 -63 c22 -34 73 -97 114 -139 l74 -78 -590 0 c-406 0 -600 -3 -622
            -11 -80 -28 -80 -160 0 -188 22 -8 258 -11 778 -11 l746 0 70 -29 c178 -75
            404 -117 570 -108 88 6 235 28 288 43 16 5 17 -8 17 -167 0 -100 -5 -188 -11
            -210 -16 -51 -60 -103 -117 -137 l-47 -27 -1314 -3 c-1446 -3 -1365 -6 -1443
            58 -20 17 -48 53 -62 82 l-26 52 0 1707 0 1707 28 53 c32 62 88 106 159 125
            28 7 108 13 194 13 l147 1 4 -112z m2408 -763 c315 -88 609 -341 736 -635 20
            -45 47 -125 62 -178 23 -87 26 -115 26 -267 0 -151 -3 -181 -26 -266 -56 -206
            -139 -357 -279 -503 -131 -137 -260 -221 -430 -280 -138 -47 -213 -58 -384
            -59 -142 -1 -171 2 -257 25 -320 87 -581 296 -726 583 -78 156 -117 349 -109
            540 12 288 114 527 313 734 166 173 366 281 606 327 119 22 350 12 468 -21z
            m1536 -2540 c86 -181 -55 -389 -262 -387 -109 1 -131 18 -505 389 l-340 338
            75 54 c88 62 212 188 276 279 l44 63 344 -343 c265 -264 350 -354 368 -393z"/>
            <path d="M3165 2230 c-220 -220 -405 -400 -410 -400 -5 0 -93 83 -195 185
            -174 173 -189 185 -223 185 -56 0 -102 -45 -102 -99 0 -41 4 -46 241 -281 232
            -232 241 -240 279 -240 39 0 49 9 492 452 309 309 454 461 458 479 13 57 -42
            119 -104 119 -33 0 -61 -26 -436 -400z"/>
        </g>
    </svg>
  )
}


