import IcoQuickly from "../../../../../../component/icon/quickly"
import { MedicalIcon } from "../../../../../../component/icon/medical"
import { IcoEstetoscopio } from "../../../../../../component"
import { PatientIcon } from "../../../../../../component/icon/patient"
import { HospitalIcon } from "../../../../../../component/icon/hospital"
import { getAttendancesHistory } from "../../../../../AttendanceManager/http"
import { getAppointmentsStats } from "../../../../http"
import { useEffect, useState } from "react"
import './HeaderAppointmentsSummary.css'

export default function HeaderAppointmentsSummary({ currentHealthPlaceUser }) {
  const [headStats, setHeadStats] = useState({ 'total_attendances_count': 0, 'current_week_attendances_count': 0, 'patient_count': 0 })
  const [currentHealthPlaceUserFirstName, setCurrentHealthPlaceUserFirstName] = useState(null)

  useEffect(() => {
    fetchAttendancesHistory()
    fetchAppointmentsStats()
    if (currentHealthPlaceUser?.user) {
      setCurrentHealthPlaceUserFirstName(getFirstName(currentHealthPlaceUser?.user?.person?.name))
    }
  }, [])

  const fetchAttendancesHistory = async (params = {}) => {
    params = {
      ...params,
      limit: 6,
      health_place__id: currentHealthPlaceUser.health_place?.id
    }

    try {
      let response = await getAttendancesHistory(params)
      setHeadStats(prev => ({ ...prev, "total_attendances_count": response.data.count }))
    } catch (err) {
      console.error('fetchAttendancesHistory', err)
    }
  }

  const fetchAppointmentsStats = async () => {
    let response = await getAppointmentsStats()


    setHeadStats(prev => ({
      ...prev,
      "current_week_attendances_count": response.data.current_week_attendances_count,
      "patient_count": response.data.patient_count
    }))
  }

  const getFirstName = (fullName) => fullName.split(" ")[0];

  return (
    <div className='HomePage-Header'>
      <div className="HomePage-Greetings">Olá, {currentHealthPlaceUserFirstName}! Aqui está o resumo dos seus atendimentos de hoje:</div>
      {/* <div className='HomePage-Header-Slot'>
        <div className='HomePage-Header-Slot-IconBox'>
          <IcoQuickly style={{ fill: 'var(--primary-font-color)', fontSize: '21px' }} />
        </div>
        <div className='HomePage-Header-Slot-Info'>
          <b>{headStats.current_week_attendances_count}</b><br />
          <span>Atendimentos na semana</span>
        </div>
      </div>
      <div className='HomePage-Header-Slot'>
        <div className='HomePage-Header-Slot-IconBox'>
          <MedicalIcon style={{ fill: 'var(--primary-font-color)', width: '21px' }} />
        </div>
        <div className='HomePage-Header-Slot-Info'>
          <b>-</b><br />
          <span>Agendamentos na semana</span>
        </div>
      </div>
      <div className='HomePage-Header-Slot'>
        <div className='HomePage-Header-Slot-IconBox'>
          <IcoEstetoscopio style={{ fill: 'var(--primary-font-color)', fontSize: '21px' }} />
        </div>
        <div className='HomePage-Header-Slot-Info'>
          <b>{headStats.total_attendances_count}</b><br />
          <span>Total de Atendimentos</span>
        </div>
      </div>
      <div className='HomePage-Header-Slot'>
        <div className='HomePage-Header-Slot-IconBox'>
          <PatientIcon style={{ fill: 'var(--primary-font-color)', width: '21px' }} />
        </div>
        <div className='HomePage-Header-Slot-Info'>
          <b>{headStats.patient_count}</b><br />
          <span>Paciente(s)</span>
        </div>
      </div> */}
      <div className='HomePage-Header-Slot'>
        <div className='HomePage-Header-Slot-IconBox'>
          <HospitalIcon style={{ fill: 'var(--primary-font-color)', width: '21px' }} />
        </div>
        <div className='HomePage-Header-Slot-Info'>
          <b>{currentHealthPlaceUser.health_place?.name || '-'}</b><br />
          <span>Instituição</span>
        </div>
      </div>
    </div>
  )
}