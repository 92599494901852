import React, { useEffect, useState } from 'react';
import { Doughnut, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import choices from './choices.json';
import './AppointmentsDoughnutSummary.css'
import { PatientIcon } from '../../../../../../component/icon/patient';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function AppointmentsSummaryDoughnut({ appointments = [] }) {
    const [doughnutChartData, setDoughnutChartData] = useState({
        labels: [],
        datasets: [{ data: [], backgroundColor: [] }],
    });

    const options = {
        "maintainAspectRatio": false,
        "aspectRatio": 0.5, // Proporção ajustada
        "plugins": {
            "title": {
                "display": true,
                "text": "Atendimentos Hoje",
                "color": "rgb(51, 51, 51)",
                "font": {
                    "size": 16,
                    "weight": "bold"
                },
                "align": "start",
                "padding": {
                    "bottom": 40
                }
            },
            "legend": {
                "position": "bottom",
                "align": "start",
                "labels": {
                    "boxWidth": 16,
                    "padding": 16
                }
            }
        },
        // cutout: '100%'
    };

    useEffect(() => {
        if (!appointments || appointments.length === 0) {
            setDoughnutChartData({
                labels: ['Sem dados'],
                datasets: [
                    {
                        data: [1],
                        backgroundColor: ['#ccc'],
                    },
                ],
            });
            return;
        }

        const calculateDoughnutData = () => {
            const counts = {};
            choices.DECISION_CHOICES.forEach(choice => {
                counts[choice.id] = 0;
            });

            appointments.forEach(appointment => {
                const decision = appointment.attendance?.decision;
                if (counts[decision] !== undefined) {
                    counts[decision]++;
                }
            });

            const labels = [];
            const data = [];
            const backgroundColors = [];

            choices.DECISION_CHOICES.forEach(choice => {
                labels.push(choice.name);
                data.push(counts[choice.id] || 0);
                backgroundColors.push(choice.color);
            });

            return {
                labels,
                datasets: [
                    {
                        data,
                        backgroundColor: backgroundColors,
                        borderColor: backgroundColors.map(color => color),
                        borderWidth: 1,
                    },
                ],
            };
        };

        setDoughnutChartData(calculateDoughnutData());
    }, [appointments]);

    return (
        <div className="Appointments-Summary-Doughnut">
            {doughnutChartData.labels.length > 0 ? (
                <Doughnut options={options} data={doughnutChartData} />
            ) : (
                <div className='NoNextAppointments-Container'>
                    <PatientIcon style={{ width: '2.5rem', fill: '#aaa' }} ></PatientIcon>
                    <p className='NoNextAppointments-Message'>Nenhum atendimento agendado para hoje</p>
                </div>
            )}
        </div>
    );
}
