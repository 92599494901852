import { useApp } from "../../layout/App";
import HomePageAdm from "./subcomponents/homeTypes/homeAdm/homeAdm";
import homeHealthProfessional from "./subcomponents/homeTypes/homeHealthProfessional/homeHealthProfessional";
import { LoadingPage } from "../../newcomponents/loading/loading";
import { useEffect, useState } from "react";

export default function HomePage() {
  const { isHealthProfessionalOrIsNurse } = useApp()
  

  const [isLoading, setIsLoading] = useState(false)

  const HomeComponent = isHealthProfessionalOrIsNurse ? homeHealthProfessional : HomePageAdm

  return (
    <>
      {isLoading && <LoadingPage/>}
      <HomeComponent setIsLoading={setIsLoading}/>
    </>
  )
}