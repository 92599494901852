import './DetailOphthalmologyInfo.css'
import React from "react";
import { handleAxis, handleCDegree, handleSignal } from "../../../../../../../../../../../../OphthalmologyTemplate/subcomponents/helpers";


export default function DetailOphthalmologyInfo({ holdingOphthalmology }) {
    console.log(holdingOphthalmology)
    return <div>
            <div className='DetailOphthalmologyInfo-Row'>
                <div>
                    <p><b>Refração Dinâmica</b></p>
                    <div className='glasses-container glasses-alignRefractionTitles'>
                    <div className='glasses-space'><b>HD</b></div>
                    <div className='glasses-degreeE'><b>Grau E</b></div>
                    <div className='glasses-degreeC'><b>Grau C</b></div>
                    <div className='glasses-axis'><b>Eixo</b></div>

                    {/* Right (OD) dynamic refraction */}
                    <div className='glasses-rEyeFlag'><p>OD</p></div>
                    <div className='glasses-rEDegreeInput'>
                        {handleSignal(holdingOphthalmology?.right_eye_dynamic_refraction_e_degree) || '-'}
                    </div>
                    <div className='glasses-rCDegreeInput'>
                        {handleCDegree(holdingOphthalmology?.right_eye_dynamic_refraction_c_degree) || '-'}
                    </div>
                    <div className='glasses-rAxisInput'>
                        {handleAxis(holdingOphthalmology?.right_eye_dynamic_refraction_axis) || '-'}
                        {holdingOphthalmology?.right_eye_dynamic_refraction_axis && '°'}
                    </div>

                    {/* Left (OE) dynamic refraction */}
                    <div className='glasses-lEyeFlag'><p>OE</p></div>
                    <div className='glasses-lEDegreeInput'>
                        {handleSignal(holdingOphthalmology?.left_eye_dynamic_refraction_e_degree) || '-'}
                    </div>
                    <div className='glasses-lCDegreeInput'>
                        {handleCDegree(holdingOphthalmology?.left_eye_dynamic_refraction_c_degree) || '-'}
                    </div>
                    <div className='glasses-lAxisInput'>
                        {handleAxis(holdingOphthalmology?.left_eye_dynamic_refraction_axis) || '-'}
                        {holdingOphthalmology?.left_eye_dynamic_refraction_axis && '°'}
                    </div>
                    </div>
                </div>
                <div>
                    <p><b>Refração Estática</b></p>
                    <div className='glasses-container glasses-alignRefractionTitles'>
                    <div className='glasses-space'><b>HD</b></div>
                    <div className='glasses-degreeE'><b>Grau E</b></div>
                    <div className='glasses-degreeC'><b>Grau C</b></div>
                    <div className='glasses-axis'><b>Eixo</b></div>
                    
                    {/* Right (OD) static refraction */}
                    <div className='glasses-rEyeFlag'><p>OD</p></div>
                    <div className='glasses-rEDegreeInput'>
                        {handleSignal(holdingOphthalmology?.right_eye_static_refraction_e_degree) || '-'}
                    </div>
                    <div className='glasses-rCDegreeInput'>
                        {handleCDegree(holdingOphthalmology?.right_eye_static_refraction_c_degree) || '-'}
                    </div>
                    <div className='glasses-rAxisInput'>
                        {handleAxis(holdingOphthalmology?.right_eye_static_refraction_axis) || '-'}
                        {holdingOphthalmology?.right_eye_static_refraction_axis && '°'}
                    </div>

                    {/* Left (OE) static refraction */}
                    <div className='glasses-lEyeFlag'><p>OE</p></div>
                    <div className='glasses-lEDegreeInput'>
                        {handleSignal(holdingOphthalmology?.left_eye_static_refraction_e_degree) || '-'}
                    </div>
                    <div className='glasses-lCDegreeInput'>
                        {handleCDegree(holdingOphthalmology?.left_eye_static_refraction_c_degree) || '-'}
                    </div>
                    <div className='glasses-lAxisInput'>
                        {handleAxis(holdingOphthalmology?.left_eye_static_refraction_axis) || '-'}
                        {holdingOphthalmology?.left_eye_static_refraction_axis && '°'}
                    </div>
                    </div>
                </div>

            </div>
            <div>
                <p><b>Observações</b></p>
                { 
                    (!holdingOphthalmology?.notes || holdingOphthalmology?.notes === '' || holdingOphthalmology?.notes === '<p><br></p>' || holdingOphthalmology?.notes?.length === 0) 
                    ? <p style={{ fontSize: '0.875rem' }}>-</p>
                    : <p className='DetailOphthalmologyInfo-Notes' style={{ fontSize: '0.875rem' }} dangerouslySetInnerHTML={{ __html: holdingOphthalmology?.notes }}></p>
                }
            </div>
    </div>
}