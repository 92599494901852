import './BookingAppointment.css';
import React, { useEffect } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import { useApp } from "../../../../layout/App";
import BookingAppointmentSelect from './subcomponents/BookingAppointmentSelect/BookingAppointmentSelect';
import BookingAppointmentCursor from './subcomponents/BookingAppointmentCursor/BookingAppointmentCursor';

export default function BookingAppointment() {
    const history = useHistory();
    const location = useLocation();
    const { currentHealthPlaceUser, isHealthProfessional } = useApp();
    const queryParams = new URLSearchParams(location.search);
    const [selectedHealthProfessional, setSelectedHealthProfessional] = React.useState(null);

    useEffect(() => {
        if (isHealthProfessional && currentHealthPlaceUser) {
            const personId = currentHealthPlaceUser?.user?.person?.id;
            if (personId) {
                history.push(`/atendimentos/agendamento?health_place_user__user__person=${personId}`);
                setSelectedHealthProfessional(currentHealthPlaceUser);
            }
        }
    }, [isHealthProfessional, currentHealthPlaceUser, history]);

    const handleSelectHealthProfessional = (selectedId) => {
        history.push(`/atendimentos/agendamento?health_place_user__user__person=${selectedId}`);
        setSelectedHealthProfessional(selectedId); // Atualiza o estado do profissional selecionado
    };

    return (
        <div className='BookingAppointment'>
            <BookingAppointmentSelect
                currentHealthPlaceUser={currentHealthPlaceUser}
                isHealthProfessional={isHealthProfessional}
                onSelect={handleSelectHealthProfessional} // Callback para atualizar URL e estado
            />
            <BookingAppointmentCursor selectedHealthProfessional={selectedHealthProfessional} onSelectProf={handleSelectHealthProfessional} />
        </div>
    );
}
