export const CHAT_ERRORS = {
    AUTH_REQUIRED: {
      code: 401,
      message: "Você precisa estar autenticado para realizar essa ação.",
    },
    GROUP_NAME_DUPLICATE: {
      code: 400,
      message: "Você já possui uma conversa em grupo com esse nome. Escolha um nome diferente.",
    },
    PRIVATE_CHAT_EXISTS: {
      code: 400,
      message: "Você já possui uma conversa privada com o participante selecionado.",
    },
    NAME_REQUIRED: {
      code: 400,
      message: "É necessário informar um nome válido para criar ou atualizar a conversa.",
    },
    CHAT_NOT_FOUND: {
      code: 404,
      message: "A conversa solicitada não foi encontrada ou você não faz parte dela.",
    },
    MIN_PARTICIPANTS_ERROR: {
      code: 400,
      message: "Não é possível remover membros. A conversa em grupo precisa ter no mínimo 3 participantes.",
    },
    VALIDATION_ERROR: {
      code: 400,
      message: "Houve um problema com os dados enviados. Verifique se todos os campos estão preenchidos corretamente.",
    },
    SERVER_ERROR: {
      code: 500,
      message: "Ocorreu um erro inesperado no sistema. Tente novamente mais tarde.",
    },
    PARTICIPANT_REQUIRED: {
        code: 400,
        message: "Você deve selecionar ao menos um participante para criar uma conversa"
    }
  };
  