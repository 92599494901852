import './EditChatModal.css';
import { AddAlert, AlertType, Input } from '../../../../../../component';
import { useEffect, useState, useRef } from 'react';
import { useApp } from '../../../../../../layout/App';
import NewMultiSelect from '../../../../../../newcomponents/form/select/multiselect';
import Button, { ButtonModel } from '../../../../../../newcomponents/button/button';
import { MODAL_INIT_PROPS } from '../../../../../../newcomponents/modal/modal';
import { useDispatch } from 'react-redux';
import { updateChatRoomName, updateChatRoomMembers } from '../../../../http';
import { IcoClose } from '../../../../../../component';
import { getCollaborators } from '../../../../../collaborators/http';
import { stringifyCollaboratorOption } from '../../../../../../utils/stringifyCollaboratorOption';
import InfoOutlinedIcon from '../../../../../../component/icon/info-outlined';
import { CHAT_ERRORS } from '../../ErrorCodes';

export default function EditChatModal({ chatInfo = {}, setModalInfo = () => null, onChatsUpdate = () => null}) {
    const [chat, setChat] = useState({ ...chatInfo, participants: [] });
    const [collaboratorOptions, setCollaboratorOptions] = useState([]);
    const [inputError, setInputError] = useState(false);
    const { currentHealthPlaceUser } = useApp();
    const searchCollaboratorsTimeout = useRef(null)

    const dispatch = useDispatch();

    useEffect(() => {
        // Carregar os participantes iniciais do chat
        if (chatInfo?.participants) {
            setChat(prev => ({ ...prev, participants: chatInfo.participants }));
        }
        
        fetchCollaboratorsOptions();
    }, [chatInfo]);

    const stringifyParticipantUser = (participant) => {
        const user = participant.user
        
        return {
            "id": user.id,
            "email": user.email,
            "name": user.person.name,
            "avatar_img_url": user.person.avatar_img_url
    }
}

    const fetchCollaboratorsOptions = async params => {
        await getCollaborators({
            ...params,
            health_place__id: currentHealthPlaceUser?.health_place?.id,
            is_active: true,
            has_person: true,
            limit: 500,
        })
            .then(res => {
                setCollaboratorOptions(res.data.results.filter(option => option.id !== currentHealthPlaceUser.id).map(instance => stringifyParticipantUser(instance)))
            })
            .catch(err => {
                console.error('fetchCollaboratorsOptions - NewChatModal', err)
                setCollaboratorOptions([])
            })
    }

    const handleSearchCollaboratorsOptions = event => {

        if (searchCollaboratorsTimeout.current)
            clearTimeout(searchCollaboratorsTimeout.current)

        searchCollaboratorsTimeout.current = setTimeout(() => {
            fetchCollaboratorsOptions({ name_cpf_or_email: event.target.value })
        }, 400, event.target.value)
    }

    const handleChange = (event) => {
        setChat(prev => ({ ...prev, [event.target.name]: event.target.value }));
    };

    const toggleParticipant = (loadKey, optionsList, fieldNameRef, optionRef) => {
        
        if (inputError) {
            setInputError(false)
        }
        const selectedOption = optionsList.find(option => option[fieldNameRef] === optionRef);
        const isExisting = chat[loadKey].find(option => option[fieldNameRef] === optionRef);

        if (isExisting) {
            setChat(prev => ({
                ...prev,
                [loadKey]: prev[loadKey].filter(option => option[fieldNameRef] !== optionRef)
            }));
        } else {
            setChat(prev => ({
                ...prev,
                [loadKey]: [...prev[loadKey], selectedOption]
            }));
        }
    };




    const handleUpdate = async () => {
        try {
            await updateChatRoomName({ room_id: chat.id, newName: chat.name });

            const currentParticipantIds = chatInfo.participants.map(p => p.id);
            const newParticipantIds = chat.participants.map(p => p.id);

            const addMembers = newParticipantIds.filter(id => !currentParticipantIds.includes(id));
            const removeMembers = currentParticipantIds.filter(id => !newParticipantIds.includes(id));

            if (addMembers.length > 0 || removeMembers.length > 0) {
                // Atualizar participantes (adicionar/remover)
                await updateChatRoomMembers({ room_id: chat.id, addMembers, removeMembers });
            }

            dispatch(AddAlert('Editar Conversa', 'Grupo atualizado com sucesso!', AlertType.SUCCESS));
            setModalInfo(MODAL_INIT_PROPS);
            onChatsUpdate(chatInfo)
        } catch (error) {
            console.error('Erro ao atualizar o grupo:', error);
            const errorCode = error?.response?.data?.error_code
            const errorMessage = CHAT_ERRORS[errorCode]?.message
            dispatch(AddAlert('Editar Conversa', errorMessage, AlertType.ERROR))
        }
    };

    return (
        <div className='EditChatModal'>
            <p>Editar Nome do Grupo:</p>
            <Input
                name='name'
                value={chat.name}
                maxLength={50}
                action={handleChange}
            />

            <div className='EditChatModal-Field-Label'>

                <span style={{ width: '1.125rem', height: '1.125rem' }} title='Deve haver ao menos 3 participantes no grupo após a edição'>
                    {chat.participants.length > 2 ? <InfoOutlinedIcon ></InfoOutlinedIcon> : <InfoOutlinedIcon style={{ fill: 'rgb(236, 106, 106)' }}></InfoOutlinedIcon>}
                </span>
                <p>Adicionar ou Remover Participantes:</p>
            </div>
            <NewMultiSelect
                filterNode={
                    <div>
                        <Input
                            placeholder='Pesquisar por e-mail, nome ou cpf'
                            action={handleSearchCollaboratorsOptions}
                            actionBlur={() => fetchCollaboratorsOptions()}
                        />
                    </div>
                }
                defaultText={chat?.participants?.length ? `${chat.participants.length} Selecionados` : 'Selecione'}
                onlyDefaultText
                onSelect={({ optionRef }) => toggleParticipant('participants', collaboratorOptions, 'id', optionRef)}
                selectedOptions={chat.participants.map(p => p.id)}
                optionRefKey='id'
                optionStrKey='name'
                options={collaboratorOptions}
                error={inputError}
            />
            <p className='EditChatModal-ParticipantsList-Label'>Selecionados:</p>
            <div className='EditChatModal-ParticipantsList'>
                {chat?.participants?.map((participant, index) => {
                    return (
                        <button key={participant.id} className='EditChatModal-ParticipantsList-Item'>
                            {participant.name}
                            <span
                                onClick={() =>  toggleParticipant('participants', collaboratorOptions, 'id', participant.id)}
                                className='EditChatModal-ParticipantsList-Item-IconBox'
                            >
                                <IcoClose />
                            </span>
                        </button>
                    );
                })}
            </div>

            <div className='EditChatModal-ActionBox'>
                <Button
                    label='Cancelar'
                    model={ButtonModel.SECONDARY}
                    onClick={() => setModalInfo(MODAL_INIT_PROPS)}
                />
                <Button
                    label='Salvar Alterações'
                    model={ButtonModel.PRIMARY}
                    onClick={handleUpdate}
                />
            </div>
        </div>
    );
}
