import './ChronicConditions.css';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ChartIcon from '../../../../../../../../component/icon/chart';
import ListIcon from '../../../../../../../../component/icon/list';
import NewTaskIcon from '../../../../../../../../component/icon/new_task';
import Modal, { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal';
import { AddAlert, AlertType } from '../../../../../../../../component';
import ShowChronicConditions from './subcomponents/ShowChronicConditions/ShowChronicConditions';
import NewEditChronicConditions from './subcomponents/NewEditChronicConditions/NewEditChronicConditions';
import TableChronicCondition from './subcomponents/TableChronicCondition/TableChronicCondition';
import DeleteChronicCondition from './subcomponents/DeleteChronicCondition/DeleteChronicCondition';
import { getChronicCondition } from '../../../../../../http';

export default function ChronicConditions({ person, attendanceID=null, }) {
  const dispatch = useDispatch()
  const [chronicConditionsHistory, setChronicConditionsHistory] = useState([])
  const [openSection, setOpenSection] = useState('TableChronicCondition')
  const [modalInfo, setModalInfo] = useState(MODAL_INIT_PROPS)
  const [pagination, setPagination] = useState({ pageNumber: 0, totalPages: 1, totalElements: 0 })

  useEffect(() => {
    fetchChronicConditions({ offset: 0 })
  }, [])

  const fetchChronicConditions = async (params={}) => {
    params = { ...params, "active": true, "person__id": person?.id }
    
    try {
      let res = await getChronicCondition(params)
      setChronicConditionsHistory(res.data.results)
      setPagination({
        totalElements: res.data.count,
        totalPages: Math.ceil(res.data.count / 10),
        pageNumber: params.offset / 10,
      })
    } catch (err) {
      dispatch(AddAlert('Doenças Crônicas', 'Falha ao obter doenças crônicas', AlertType.ERROR))
      console.error('Doenças Crônicas ~ fetchChronicConditions ~ ', err)
    }
  }

  const extraAction = async () => {
    await fetchChronicConditions({ offset: 0, active: true })
    setModalInfo(MODAL_INIT_PROPS)
  }
  
  const openNewEditChronicConditionModal = (cursor) => {
    setModalInfo(prev => ({
      ...prev,
      open: true,
      title: 'Doenças Crônicas',
      content: <NewEditChronicConditions
        initChronicCondition={cursor}
        person={person}
        attendanceID={attendanceID}
        extraAction={extraAction} 
      />
    }))
  }
  
  const openDeleteChronicConditionModal = (cursor) => {
    setModalInfo(prev => ({
      ...prev,
      open: true,
      title: 'Deleção de Doença Crônica',
      content: <DeleteChronicCondition
        chroniccondition={cursor} 
        extraAction={extraAction}
      />
    }))
  }

  const sections = {
    'ShowChronicConditions': <ShowChronicConditions 
      chronicConditionsHistory={chronicConditionsHistory}
      fetchChronicConditions={fetchChronicConditions}
      setModalInfo={setModalInfo} 
    />,
    'TableChronicCondition': <TableChronicCondition 
      chronicConditionsHistory={chronicConditionsHistory}
      fetchChronicConditions={fetchChronicConditions} 
      openNewEditChronicConditionModal={openNewEditChronicConditionModal}
      openDeleteChronicConditionModal={openDeleteChronicConditionModal}
      pagination={pagination}
    />
  }

  return (
    <div>
      <Modal {...modalInfo} dismissFn={() => setModalInfo(MODAL_INIT_PROPS)} />
      <div className='ChronicConditions-ActionRow'>
        <div
          onClick={() => openNewEditChronicConditionModal()}
          title='Nova condição crônica'
        >
          <NewTaskIcon />
        </div>
        {openSection === 'ShowChronicConditions' ? (
          <div onClick={() => setOpenSection('TableChronicCondition')} title='Ver Dados'>
            <ListIcon />
          </div>
        ) : (
          <div onClick={() => setOpenSection('ShowChronicConditions')} title='Ver Gráficos'>
            <ChartIcon />
          </div>
        )}
      </div>
      <div>
        { sections[openSection] }
      </div>
    </div>
  );
}
