import './TablePhysicalAssessment.css'
import React, { useEffect } from 'react'
import { IcoBin, IcoPencil, List, MaskDataHora, Paginate } from '../../../../../../../../../../component';

const defaultOptions = require('../../../../../../../../../../component/form/select/options.json')

export default function TablePhysicalAssessment({ physicalAssessmentHistory=[], fetchPhysicalAssessmentHistory= async () => null, openNewEditShowPhysicalAssessmentModal=() => null, openDeletePhysicalAssessmentModal=() => null, pagination={} }) {
    useEffect(() => {
        fetchPhysicalAssessmentHistory({ offset: 0, limit: 10 })
    }, [])

    const handleNextPage = async e => {
        let offset = e * 10
		await fetchPhysicalAssessmentHistory({ "offset": offset, limit: 10 })
	}

    const headDefault = [
        { "colunm": "h_physical_mobility_mmss", "text": "MMSS" },
        { "colunm": "h_physical_mobility_mmii", "text": "MMII" },
        { "colunm": "h_skin", "text": "Pele" },
        { "colunm": "h_turgor_skin", "text": "Turgor" },
        { "colunm": "h_mucosa_skin", "text": "Mucosa" },
        { "colunm": "h_skin_injures", "text": "Lesões", "width": "300px" },
        { "colunm": "h_updated_at", "text": "Últ. alt.", "width": "100px"  },
        { "colunm": "h_appointment_date", "text": "Data de Atend.", "width": "100px" },
        { "colunm": "h_action", "text": <div className='TablePhysicalAssessment-HeaderRow-Action'>Ações</div>, width: '72px' } 
    ]

    return <div>
        <div className='TablePhysicalAssessment-Body-List'>
            <List
                head={headDefault}
                data={physicalAssessmentHistory}
                listCustom={cursor => {
                    const custom = cursor;
                    const renderTranslatedMMSS = (selected) => {
                        return defaultOptions.PHYSICAL_MOBILITY_MMSS_CHOICES.find(({id}) => id === selected)?.name  || '-'
                    }
                    const renderTranslatedMMII = (selected) => {
                        return defaultOptions.PHYSICAL_MOBILITY_MMII_CHOICES.find(({id}) => id === selected)?.name  || '-'
                    }
                    const renderTranslatedSkin = (selected) => {
                        return defaultOptions.SKIN_CHOICES.find(({id}) => id === selected)?.name  || '-'
                    }
                    const renderTranslatedTurgorSkin = (selected) => {
                        return defaultOptions.TURGOR_CHOICES.find(({id}) => id === selected)?.name  || '-'
                    }
                    const renderTranslatedMucosaSkin = (selected) => {
                        return defaultOptions.MUCOSA_CHOICES.find(({id}) => id === selected)?.name  || '-'
                    }

                    custom['h_physical_mobility_mmss'] = renderTranslatedMMSS(cursor?.physical_mobility_mmss)
                    custom['h_physical_mobility_mmii'] = renderTranslatedMMII(cursor?.physical_mobility_mmii)
                    custom['h_skin'] = renderTranslatedSkin(cursor?.skin)
                    custom['h_turgor_skin'] = renderTranslatedTurgorSkin(cursor?.turgor_skin)
                    custom['h_mucosa_skin'] = renderTranslatedMucosaSkin(cursor?.mucosa_skin)
                    custom['h_skin_injures'] = cursor?.skin_injures 
                        ? <p dangerouslySetInnerHTML={{ __html: cursor?.skin_injures }}></p> 
                        : '-'
                    custom['h_updated_at'] = MaskDataHora(cursor?.updated_at)?.data || '-'
                    custom['h_appointment_date'] = MaskDataHora(cursor?.attendance?.start)?.data || '-'
                    custom['h_action'] = (
                        <div className='TablePhysicalAssessment-ActionRow'>
                            <div
                                title='Editar Avaliação Física'
                            	onClick={() => openNewEditShowPhysicalAssessmentModal(cursor)}
                            >
                            	<IcoPencil />
                            </div>
                            <div
                                title='Excluir Avaliação Física'
                                onClick={() => openDeletePhysicalAssessmentModal(cursor)}
                            >
                                <IcoBin />
                            </div>
                        </div>
                    )
                    return custom
                }}
            />
            <div className='TablePhysicalAssessment-Pagination'>
                <Paginate data={pagination} action={handleNextPage} />
            </div>
        </div>
        <div className='TablePhysicalAssessment-Legend'>
            <span>Legenda:</span>
            <ul>
                <li><strong>MMSS</strong> - Membros Superiores</li>
                <li><strong>MMII</strong> - Membros Inferiores</li>
                <li><strong>Últ. alt.</strong> - Última Alteração</li>
                <li><strong>Data Atend.</strong> - Data de Atendimento</li>
            </ul>
        </div>
    </div>
}
